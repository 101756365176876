import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import './App.scss';
import AddInfoPodcast from './containers/AddInfoPodcast/AddInfoPodcast';
import FastLoginCallback from './containers/Auth/FastLogin/FastLoginCallback';
import Logout from './containers/Auth/Logout/Logout';
import Register from './containers/Auth/Register/Register';
import CreatePodcast from './containers/CreatePodcast/CreatePodcast';
import FaqNew from './containers/FaqNew/FaqNew';
import Home from './containers/Home/Home';
import Moderation from './containers/Moderation/Moderation';
import MyAgreements from './containers/MyAgreements/MyAgreements';
import AgreementContainer from './containers/MyAgreements/Agreement/AgreementContainer';
import PrivacyContainer from './containers/MyAgreements/Privacy/PrivacyContainer';
import NoPodcast from './containers/NoPodcast/NoPodcast';
import Podcasts from './containers/Podcasts/Podcasts';
import Layout from './hoc/Layout/Layout';
import { authCheckState } from './store/auth/login/actions';
import { LoginActionTypes } from './store/auth/login/types';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & any;

class App extends React.Component<Props> {
  componentDidMount() {
    this.props.authCheckState();
  }
  fastLoginClick = () => {
    // const url = `${process.env.REACT_APP_FAST_LOGIN_APP_URL}` + `&serviceRedirectUrl=`+ document.location.origin +`/fast-login`;
    // console.log("********prtl*****dev******",url);
    const url = `${process.env.REACT_APP_FAST_LOGIN_APP_URL}`;
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;
    const top = (windowHeight) ? (windowHeight - 600) / 2 : 0;
    const left = (windowWidth) ? (windowWidth - 500) / 2 : 0;
    const properties = `height=600,
                        width=500,
                        left=${left},
                        top=${top},
                        resizable=yes,
                        scrollbars=yes,
                        toolbar=yes,
                        menubar=no,
                        location=no,
                        directories=no,
                        status=yes`;
    if (url !== null) {
      window.open(url, '_self', properties);

    }
  }

  renderRoutes = () => {
    // console.log('renderRoutes', this.props);
    const role = this.props.userRole;

    if (this.props.isAuthenticated) {
      if (role?.toString().toLowerCase() === "podcastmoderator" || role?.toString().toLowerCase() === "moderator") {
        return (
          <Switch>
            <Route exact path="/podcast-moderation" component={Moderation} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path='/yardim' component={FaqNew} />
            <Route exact path='/yardim/:param' render={(props) => <FaqNew {...props} />} />
            <Route exact path='/my-agreements' component={MyAgreements} />
            <Route exact path='/user-agreement' component={AgreementContainer} />
            <Route exact path='/privacy-policy' component={PrivacyContainer} />
            <Redirect to="/podcast-moderation" />
            <Route path="*" component={Moderation} />
          </Switch>
        )
      } else {

        return (
          <Switch>
            <Route exact path="/my-podcasts" component={Podcasts} />
            <Route exact path="/podcast-create" component={CreatePodcast} />
            <Route exact path="/podcast-verify" component={AddInfoPodcast} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path='/yardim' component={FaqNew} />
            <Route exact path='/yardim/:param' component={FaqNew} />
            <Route exact path='/my-agreements' component={MyAgreements} />
            <Route exact path='/no-podcast' component={NoPodcast} />
            <Route exact path='/user-agreement' component={AgreementContainer} />
            <Route exact path='/privacy-policy' component={PrivacyContainer} />
            <Redirect to="/my-podcasts" />
          </Switch>
        );
      }
    } else {
      return (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path='/fast-login' component={FastLoginCallback} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/yardim' component={FaqNew} />
          <Route exact path='/yardim/:param' component={FaqNew} />
          {/* son kullanıcı sözleşmesi */}
          <Route exact path='/user-agreement' component={AgreementContainer} />
          {/* gizlilik politikası */}
          <Route exact path='/privacy-policy' component={PrivacyContainer} />
          <Route path="*" component={Home} />
        </Switch>
      );
    }
  }
  render() {

    return (
      <Layout onClick={this.fastLoginClick}>
        {this.renderRoutes()}
      </Layout>

    );
  }
};

const mapDispatchToProps = (dispatch: Dispatch<LoginActionTypes>) => {
  return bindActionCreators({
    authCheckState
  }, dispatch);
};


const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.loginStore.token && state.loginStore.agreementApproved,
    userName: state.loginStore.userInfo,
    userRole: state.loginStore.userRole
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
