import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Card from '../../../components/UI/Card/Card';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { login } from '../../../store/auth/login/actions';
import { LoginActionTypes } from '../../../store/auth/login/types';
import { fastLoginErrorFormat, queryStringFormat } from '../../../store/shared/utility';
import errorIcon from '../../../assets/images/icons/error-icon.png';
import Error from '../../../components/Error/Error';
import './FastLoginCallBack.scss';
import Button from '../../../components/UI/Button/Button';
type Props = ReturnType<typeof mapStateToProps> &
ReturnType<typeof mapDispatchToProps> & any ;


class FastLoginCallBack extends React.Component<Props> {
  
    state = {
        errorCode:'',
    }
    componentDidMount() {
        const fastLoginToken = queryStringFormat(window.location.search, 'token');
        const errorCode = queryStringFormat(window.location.search, 'resultCode');
        if(fastLoginToken) {
            this.props.login(fastLoginToken);        
        }  
        if (errorCode){
            
            this.setState({errorCode:errorCode});
        }
    
    }
 
    componentDidUpdate(prevProps:any) {
        if(prevProps.loginStore !== this.props.loginStore ) {
            if(this.props.isAuthenticated) {
                this.props.history.push(this.props.redirectPath)
            } 
        }
    }
    handleErrorMessage = () => {
    const errorCode = queryStringFormat(window.location.search, 'resultCode');
    return fastLoginErrorFormat(errorCode || '');
    }
    renderFastLogin() {
        if(this.props.loading || this.props.isAuthenticated) {
                return <Spinner />;
        } if(this.props.error || this.state.errorCode !== '') {
            return  (
                <Card>
                    <img alt="" src={errorIcon} /> 
                    <h2>Hata</h2>
                    <Error message = {this.handleErrorMessage()} />
                    <Button text="Tekrar Dene" onClick={() => window.location.href= '/'} />
                </Card>
            );  
        }
    }
 
    render() {
        return (
            <div id="fast-login-page" className="layout login">
                    {this.renderFastLogin()}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch<LoginActionTypes>) =>{
   
    return bindActionCreators(
        {login} ,
       dispatch);

};

  
const mapStateToProps = (state:any) => {
  return {
      loginStore:state.loginStore,
    loading:state.loginStore.loading,
    isAuthenticated: state.loginStore.token,
    redirectPath: state.loginStore.redirectPath,
    error: state.loginStore.error
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(FastLoginCallBack);