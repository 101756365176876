import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Agreement from '../../components/Agreement/Agreement';
import PrivacyAgreement from '../../components/PrivacyAgreement/PrivacyAgreement';
import Card from '../../components/UI/Card/Card';
import { fetchAgreement } from '../../store/auth/register/actions';
import { AgreementActionTypes } from '../../store/auth/register/types';

type Props = ReturnType<typeof mapStateToProps> &
ReturnType<typeof mapDispatchToProps> & any;
class MyAgreements extends React.Component <Props> {
componentDidMount() {
    this.props.fetchAgreement();
}

render() {
    return <div id="my-agreements-page" className="layout">
        <Card>
        <h2>Sözleşmelerim</h2>
        <Agreement loading={this.props.agreement.loading} agreement={this.props.agreement.agreement} error= {this.props.agreement.message}/>
        <PrivacyAgreement />
        </Card>

    </div>
}
}

const mapDispatchToProps = (dispatch: Dispatch< AgreementActionTypes>) =>{
   
    return bindActionCreators(
        {fetchAgreement} ,
       dispatch);
  
  };
  
  
  const mapStateToProps = (state:any) => {
  
  return {
     
     agreement:state.agreement
  };
  };
export default connect(mapStateToProps,mapDispatchToProps) (MyAgreements);