import React from "react";
import { dateFormat } from "../../store/shared/utility";
import Error from "../Error/Error";
import Spinner from "../UI/Spinner/Spinner";
import "./Agreement.scss";
const Agreement = (props: any) => {
  return (
    <div className="contract-box">
      <div className="contract-box--content">
        <div className="contract-box--content-info">
          {props.children ? (
            props.children
          ) : props.loading ? (
            <Spinner />
          ) : props.agreement ? (
            <div>
              <h2>{props.agreement.title}</h2>
              <p>Oluşturma tarihi: {dateFormat(props.agreement.createdAt)}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: props.agreement.description,
                }}
              />
            </div>
          ) : (
            <Error message={props.error} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Agreement;
