
export const FETCH_AGREEMENT_START = 'FETCH_AGREEMENT_START';
export const FETCH_AGREEMENT_SUCCESS = 'FETCH_AGREEMENT_SUCCESS';
export const FETCH_AGREEMENT_FAIL = 'FETCH_AGREEMENT_FAIL';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';


interface FetchAgreementStartAction {
    type: typeof FETCH_AGREEMENT_START
}
interface FetchAgreementSuccessAction {
    type:typeof FETCH_AGREEMENT_SUCCESS,
    payload:any
}
interface FetchAgreementFailAction {
    type:typeof FETCH_AGREEMENT_FAIL,
    error:any
}
interface RegisterStartAction {
    type:typeof REGISTER_START
}
interface RegisterSuccessAction {
    type:typeof REGISTER_SUCCESS,
    payload:any
}
interface RegisterFailAction {
    type:typeof REGISTER_FAIL,
    error:any
}
export interface AgreementState {
    agreement: any,
    loading: boolean,
    error:any,
    message:string
}
export interface RegisterState {
    success: boolean,
    loading: boolean,
    error:any,
    message:string
}
export type AgreementActionTypes = (
    FetchAgreementStartAction | 
    FetchAgreementSuccessAction | 
    FetchAgreementFailAction 
);
export type RegisterActionTypes = (
    RegisterStartAction | 
    RegisterSuccessAction | 
    RegisterFailAction 
);