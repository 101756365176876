import _ from 'lodash';
import { UrlValidationResponse } from "../createPodcast/types";
import { IPodcast } from '../podcasts/interfaces';


export const statusFormat = (data: IPodcast) => {

    if (data.approveStatus === 2) {
        return { status: false, text: 'ONAYLANMADI', className: 'status fail' }
    } else if (data.process === 0 || data.process === 1) {
        return { status: false, text: 'BEKLEMEDE', className: 'status in-progress' }
    } else if (data.process === 2) {

        if (data.approveStatus === 0) {
            return { status: false, text: 'BEKLEMEDE', className: 'status in-progress' }
        }
        else if (data.approveStatus === 1) {
            if (data.userStatus === 1) {
                return { status: true, text: 'YAYINDA', className: 'status success' }
            } else {
                return { status: true, text: 'TASLAK', className: 'status draft' }
            }
        }
    } else { // process === 3
        return { status: false, text: 'HATALI', className: 'status fail' }
    }
    return;
}
export const dateFormat = (date: string) => {
    // const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const d = new Date(date);
    return d.toLocaleDateString('tr-TR', { year: 'numeric', month: 'short', day: 'numeric' });
}
export const checkValidationResponse = (response: UrlValidationResponse) => {
    const result = _.pickBy(response.errorList, function (value, key) {
        return value === false && key !== 'unique';
    });

    return result;
}
export const emailChangeErrorFormat = (errorMessage?: string) => {
    switch (errorMessage) {
        case 'TIME_EXPIRED':
            return { message: 'Doğrulama kodu süresi bitmiş. Yeni bir doğrulama kodu isteyin.', inputDisplay: false };
        case 'CODE_EXPIRED':
            return { message: 'Doğrulama kodu geçerliliğini yitirmiş. Yeni bir doğrulama kodu isteyin.', inputDisplay: false };
        case 'WRONG_CODE':
            return { message: 'Geçersiz kod. Tekrar deneyin veya yeni bir doğrulama kodu isteyin.', inputDisplay: true };
        default:
            return { message: 'Bir şeyler ters gitti.', inputDisplay: false };
    }
}

export const updatePodcasts = (podcastsData: IPodcast[], podcastId: number, status: number): IPodcast[] => {
    if (podcastsData.length > 0) {
        podcastsData.map((podcast: IPodcast) => {
            if (podcast.id === podcastId) {
                _.set(podcast, 'userStatus', status);
            }
        });
        return podcastsData;
    } else {
        return [];
    }
}

export const queryStringFormat = (fullQueryString: string, searchQueryString: string) => {
    let params = new URLSearchParams(fullQueryString);
    return params.get(searchQueryString) || null;
}

export const fastLoginErrorFormat = (errorCode?: string) => {
    switch (errorCode) {
        case '101':
            return "Eksik parametre bulunmaktadır.";
        case '525':
            return "Bu servis ID’ye bağlı bir servis sağlayıcı bulunmamaktadır.";
        case '2':
            return "Lütfen girmiş olduğunuz bilgileri kontrol ederek tekrar deneyiniz.";
        case '4':
            return "Bu token geçerli değildir.";
        case '523':
            return "Doğrulama yapmak için (servisi çağırmak için) bu IP’lerin yetkisi yoktur. IP’lere yetki verilmesi için servis sağlayıcı kaydı sayfasından IP bilgileri alanına IP’lerin eklenmesi gerekmektedir.";
        default:
            return "Giriş işlemi başarısız olmuştur.";
    }
}