import { updatePodcasts } from "../shared/utility";
import * as types from "./types";

const podcastsInitialState: types.PodcastsState = {
    podcasts: {
        content: [],
        number: 0,
        size: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        first: false,
        last: false,
        sort: null,
    },
    moreLoading: false,
    loading: false,
    message: '',
    error: false
};

const episodesInitialState: types.AiEpisodesState = {
    episodes: {
        content: [],
        number: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        first: false,
        last: false,
        sort: null
    },
    moreLoading: false,
    loading: false,
    error: false,
    message: ''
};

const changelogsInitialState: types.ChangelogsState = {
    logs: undefined,
    loading: false,
}

const aiExplicitLogsInitialState: types.AiExplicitLogsState = {
    logs: undefined,
    loading: false,
}

const updatePodcastInitialState: types.UpdatePodcastState = {
    success: false,
    message: '',
    loading: false,
    error: false,
    updatedPodcastId: 0
};

const fetchPodcastsReducer = (
    state = podcastsInitialState,
    action: types.PodcastsActionTypes
): types.PodcastsState => {
    // console.log('action podc', action)

    switch (action.type) {
        case types.FETCH_PODCASTS_START:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_PODCASTS_SUCCESS:
            return {
                ...state,
                podcasts: action.payload,
                loading: false
            }
        case types.FETCH_PODCASTS_FAIL:
            return {
                ...state,
                loading: false,
                message: 'Podcast yükleme işlemi başarısız.',
                error: action.error
            }
        case types.FETCH_PODCASTS_RESET:
            return {
                ...podcastsInitialState
            }
        case types.UPDATE_PODCASTS_SUCCESS:
            return {
                ...state,
                podcasts: {
                    ...state.podcasts,
                    content: updatePodcasts(state.podcasts.content, action.podcastId, action.status)
                }
            }
        case types.FETCH_MORE_PODCASTS_START:
            return {
                ...podcastsInitialState,
                podcasts: state.podcasts,
                moreLoading: true
            }
        case types.FETCH_MORE_PODCASTS_SUCCESS:
            return {
                ...podcastsInitialState,
                podcasts: {
                    ...action.payload,
                    content: [...state.podcasts.content, ...action.payload.content]
                },
                moreLoading: false
            }
        case types.FETCH_MORE_PODCASTS_FAIL:
            return {
                loading: false,
                podcasts: state.podcasts,
                message: 'Podcast\'e ait episode kayıtları yükleme işlemi başarısız.',
                moreLoading: false,
                error: action.error
            }
        case types.FETCH_MORE_PODCASTS_RESET:
            return {
                ...podcastsInitialState
            }
        default:
            return state
    }
}

const fetchAiEpisodesReducer = (
    state = episodesInitialState,
    action: types.AiEpisodesActionTypes
): types.AiEpisodesState => {

    switch (action.type) {
        case types.FETCH_AIEPISODES_START:
            return {
                ...state,
                loading: true
            }

        case types.FETCH_AIEPISODES_SUCCESS:
            return {
                ...state,
                episodes: action.payload,
                loading: false
            }

        case types.FETCH_AIEPISODES_FAIL:
            return {
                ...state,
                loading: false,
                message: 'Podcast\'e ait episode kayıtlarını yükleme işlemi başarısız.',
                error: action.error
            }

        case types.FETCH_AIEPISODES_RESET:
            return {
                ...episodesInitialState,
                loading: false
            }

        // case types.FETCH_MORE_EPISODES_START:
        //     return {
        //         ...state,
        //         moreLoading: true
        //     }

        // case types.FETCH_MORE_EPISODES_SUCCESS:
        //     return {
        //         ...state,
        //         episodes: {
        //             ...action.payload,
        //             content: [...state.episodes.content, ...action.payload.content]
        //         },
        //         moreLoading: false
        //     }
        // case types.FETCH_MORE_EPISODES_FAIL:
        //     return {
        //         ...state,
        //         error: action.error,
        //         moreLoading: false
        //     }

        default:
            return state
    }
}

const fetchAiExplicitLogsReducer = (
    state = aiExplicitLogsInitialState,
    action: types.AiExplicitLogsActionTypes
): types.AiExplicitLogsState => {

    switch (action.type) {
        case types.FETCH_AIEXPLICITLOGS_START:
            return {
                ...state,
                loading: true,
            }
        case types.FETCH_AIEXPLICITLOGS_RESET:
            return {
                ...aiExplicitLogsInitialState
            }
        case types.FETCH_AIEXPLICITLOGS_SUCCESS:
            return {
                ...state,
                logs: action?.payload,
                loading: false,
            }
        case types.FETCH_AIEXPLICITLOGS_FAIL:
            return {
                ...aiExplicitLogsInitialState
            }
        default:
            return state
    }
}

const fetchChangelogsReducer = (
    state = changelogsInitialState,
    action: types.ChangelogsActionTypes
): types.ChangelogsState => {

    switch (action.type) {
        case types.FETCH_CHANGELOGS_START:
            return {
                ...state,
                loading: true,
            }
        case types.FETCH_CHANGELOGS_RESET:
            return {
                ...changelogsInitialState
            }
        case types.FETCH_CHANGELOGS_SUCCESS:
            return {
                ...state,
                logs: action.payload,
                loading: false,
            }
        case types.FETCH_CHANGELOGS_FAIL:
            return {
                ...changelogsInitialState
            }
        default:
            return state
    }
}

const updatePodcastReducer = (
    state = updatePodcastInitialState,
    action: types.UpdatePodcastActionTypes
): types.UpdatePodcastState => {

    switch (action.type) {
        case types.UPDATE_PODCAST_START:
            return {
                ...state,
                loading: true,
                message: 'Güncelleniyor.',
                updatedPodcastId: action.updatedPodcastId

            }
        case types.UPDATE_PODCAST_SUCCESS:
            return {
                ...state,
                success: true,
                message: 'Güncelleme işlemi başarılı.',
                loading: false
            }
        case types.UPDATE_PODCAST_FAIL:
            return {
                ...state,
                loading: false,
                message: 'Güncelleme işlemi başarısız.',
                error: true
            }
        case types.UPDATE_PODCAST_RESET:
            return {
                ...updatePodcastInitialState
            }
        default:
            return state
    }
}

const modPodcastsReducer = {
    podcasts: fetchPodcastsReducer,
    updatePodcast: updatePodcastReducer,
    changelogs: fetchChangelogsReducer,
    aiEpisodes: fetchAiEpisodesReducer,
    aiExplicitLogs: fetchAiExplicitLogsReducer,
};
export default modPodcastsReducer;
