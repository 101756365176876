import { AxiosResponse } from "axios";
import _ from 'lodash';
import { Dispatch } from "redux";
import axios from "../shared/axios.interceptor";
import { checkValidationResponse, emailChangeErrorFormat } from "../shared/utility";
import { EmailChangeVerificationActionTypes, EmailMatchVerificationActionTypes, EMAIL_CHANGE_VERIFICATION_FAIL, EMAIL_CHANGE_VERIFICATION_INITIALIZE, EMAIL_CHANGE_VERIFICATION_START, EMAIL_CHANGE_VERIFICATION_SUCCESS, EMAIL_MATCH_VERIFICATION_FAIL, EMAIL_MATCH_VERIFICATION_START, EMAIL_MATCH_VERIFICATION_SUCCESS, GetCategoriesStartActionTypes, GET_CATEGORIES_FAIL, GET_CATEGORIES_INITIALIZE, GET_CATEGORIES_START, GET_CATEGORIES_SUCCESS, UrlValidationActionTypes, URL_VALIDATION_FAIL, URL_VALIDATION_INITIALIZE, URL_VALIDATION_START, URL_VALIDATION_SUCCESS } from './actionTypes';
import { GetCategoriesResponse, UrlValidationResponse } from "./types";

//FOR URL VALIDATION
export const urlValidationStart = (): UrlValidationActionTypes => {
    return {
        type: URL_VALIDATION_START
    };
}
export const urlValidationInitialize = (): UrlValidationActionTypes => {
    return {
        type: URL_VALIDATION_INITIALIZE
    };
}

export const urlValidationSuccess = (payload: UrlValidationResponse, rssUrl: string | null): UrlValidationActionTypes => {
    return {
        type: URL_VALIDATION_SUCCESS,
        payload: payload,
        rssUrl: rssUrl
    };
}

export const urlValidationFail = (error: any): UrlValidationActionTypes => {
    return {
        type: URL_VALIDATION_FAIL,
        error: error
    };
}

export function validateUrl(url: string) {
    return (dispatch: Dispatch) => {

        dispatch(urlValidationStart());
        axios
            .post(`${process.env.REACT_APP_BASE_URL}/podcasts/validate`,
                { url: url }
            )
            .then((res: AxiosResponse<UrlValidationResponse>) => {
                const validationResult = checkValidationResponse(res.data);
                if (_.size(validationResult) > 0) {
                    let arr: any = [];
                    _.mapKeys(validationResult, function (value, key) {
                        return arr.push(_.capitalize(key))
                    });
                    const fieldsArr: any[] = arr;
                    const errListArr: any[] = [];
                    // console.log('............................', arr, fieldsArr);
                    fieldsArr.forEach(e => {
                        // console.log(e.toLowerCase());
                        switch (e.toLowerCase()) {
                            case 'imgurl':
                                errListArr.push('- Kapak Resmi');
                                break;
                            case 'author':
                                errListArr.push('- Yazar');
                                break;
                            case 'description':
                                errListArr.push('- Açıklama');
                                break;
                            case 'episode':
                                errListArr.push('- Bölüm');
                                break;
                            case 'title':
                                errListArr.push('- Başlık');
                                break;
                            case 'email':
                                errListArr.push('- Email adresi');
                                break;
                            case 'anchoremail':
                                errListArr.push('anchoremail');
                                break;
                            default:
                                break;
                        }

                    });
                    // console.log(errListArr);
                    let error = {
                        message: 'Podcast RSS içerisinde bazı bilgiler eksik:',
                        missingInfo: errListArr
                    };

                    dispatch(urlValidationFail(error));

                } else {
                    dispatch(urlValidationSuccess(res.data, url));
                }
            })
            .catch(err => {
                let error = {
                    message: 'URL hatalı gözüküyor. Lütfen kontrol edin.',
                    missingInfo: []
                };
                dispatch(urlValidationFail(error));
            });
    };
};

export const verificationEmailMatchStart = (): EmailMatchVerificationActionTypes => {
    return {
        type: EMAIL_MATCH_VERIFICATION_START
    };
}


export const verificationEmailMatchSuccess = (payload: any): EmailMatchVerificationActionTypes => {
    return {
        type: EMAIL_MATCH_VERIFICATION_SUCCESS,
        payload: payload
    };
}

export const verificationEmailMatchFail = (error: any): EmailMatchVerificationActionTypes => {
    return {
        type: EMAIL_MATCH_VERIFICATION_FAIL,
        error: error
    };
}

export function verifyMatchEmail(url: string, categoryId?: number) {

    const reqBody =
        categoryId ? {
            url: url,
            categoryId: categoryId
        } : {
            url: url
        };

    return (dispatch: Dispatch) => {
        dispatch(verificationEmailChangeInitialize());

        dispatch(verificationEmailMatchStart());
        axios.post(`${process.env.REACT_APP_BASE_URL}/podcasts/verify`, reqBody)
            .then((res: any) => {

                if (res.data.result) {
                    dispatch(verificationEmailMatchSuccess(res.data));
                } else {
                    dispatch(verificationEmailMatchFail('Günlük limite ulaşıldı.'));
                }
            })
            .catch(err => {

                dispatch(verificationEmailMatchFail('Bir hata oluştu.'));
            });
    };
};

export const verificationEmailChangeStart = (): EmailChangeVerificationActionTypes => {
    return {
        type: EMAIL_CHANGE_VERIFICATION_START
    };
}
export const verificationEmailChangeInitialize = (): EmailChangeVerificationActionTypes => {
    return {
        type: EMAIL_CHANGE_VERIFICATION_INITIALIZE
    };
}

export const verificationEmailChangeSuccess = (payload: any): EmailChangeVerificationActionTypes => {
    return {
        type: EMAIL_CHANGE_VERIFICATION_SUCCESS,
        payload: payload
    };
}

export const verificationEmailChangeFail = (error: any): EmailChangeVerificationActionTypes => {
    return {
        type: EMAIL_CHANGE_VERIFICATION_FAIL,
        error: error
    };
}

export function verifyChangeEmail(code: string, rss: string, categoryId: null) {
    return (dispatch: Dispatch) => {

        dispatch(verificationEmailChangeStart());
        axios.post(`${process.env.REACT_APP_BASE_URL}/podcasts/code`,
            {
                code: code,
                categoryId: categoryId
            }
        )
            .then((res: any) => {
                if (res.data.success) {
                    dispatch(verificationEmailChangeSuccess(res.data))
                } else {
                    const message = emailChangeErrorFormat(res.data.message);
                    dispatch(verificationEmailChangeFail(message));
                }
            })
            .catch(err => {
                const message = emailChangeErrorFormat('UNEXPECTED');

                dispatch(verificationEmailChangeFail(message));
            });
    };
};

export function getCategories() {
    return (dispatch: Dispatch) => {

        dispatch(getCategoriesStart());
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/podcasts/categories`)
            .then((res: any) => {
                if (res.status === 200) {
                    dispatch(getCategoriesSuccess(res.data))
                } else {
                    dispatch(getCategoriesFail('Kategoriler çekilirken hata oluştu'));
                }
            })
            .catch(err => {
                dispatch(verificationEmailChangeFail(true));
            });
    };
};

export const getCategoriesStart = (): GetCategoriesStartActionTypes => {
    return {
        type: GET_CATEGORIES_START
    };
}
export const getCategoriesInitialize = (): GetCategoriesStartActionTypes => {
    return {
        type: GET_CATEGORIES_INITIALIZE
    };
}

export const getCategoriesSuccess = (payload: GetCategoriesResponse): GetCategoriesStartActionTypes => {
    return {
        type: GET_CATEGORIES_SUCCESS,
        payload: payload
    };
}

export const getCategoriesFail = (error: any): GetCategoriesStartActionTypes => {
    return {
        type: GET_CATEGORIES_FAIL,
        error: error
    };
}