import React, { useEffect, useState } from 'react';
import './Timer.scss';
const Timer = (props:any) => {
    const [timeBound, setTimeBound] = useState(300000);
    const [isActive, setIsActive] = useState(props.active);
    const [minute, setMinute] = useState('');
    const [second, setSecond] = useState('');

   
  
 
  
    useEffect(() => {
      let interval:any = null;
      if (isActive && timeBound >= 0) {
        interval = setInterval(() => {
            setTimeBound(timeBound => timeBound - 1000);

          let minutes = parseInt((timeBound / 60000).toString(), 10);
          let seconds = parseInt(((timeBound % 60000) / 1000).toString() , 10);
          setMinute(`${minutes < 10 ? '0' + minutes : minutes}`);
          setSecond(`${seconds < 10 ? '0' + seconds : seconds}`);
            if(timeBound === 0) {
                props.timerStopChange();
            }
       
        }, 1000);
      } else {
       
          setIsActive(false);
          setTimeBound(0);
          clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [isActive, timeBound,props]);
  
    return (
       second !== '' && minute !== '' ?
    <p className="timer">{minute} : {second} </p>: null
    )
}
export default Timer;