import { Dispatch } from "redux";
import axios from "../shared/axios.interceptor";
import { IPaginationRequest } from "../shared/type";
import { IEpisodeList } from "./interfaces";
import * as types from "./types";
export const fetchEpisodesStart = (): types.EpisodesActionTypes => {
  return {
    type: types.FETCH_EPISODES_START,
  };
};

export const fetchEpisodesSuccess = (
  episodes: IEpisodeList
): types.EpisodesActionTypes => {
  return {
    type: types.FETCH_EPISODES_SUCCESS,
    payload: episodes,
  };
};

export const fetchEpisodesFail = (error: any): types.EpisodesActionTypes => {
  return {
    type: types.FETCH_EPISODES_FAIL,
    error: error,
  };
};

export const fetchEpisodesReset = (): types.EpisodesActionTypes => {
  return {
    type: types.FETCH_EPISODES_RESET,
  };
};

export const fetchMoreEpisodesStart = (): types.EpisodesActionTypes => {
  return {
    type: types.FETCH_MORE_EPISODES_START,
  };
};
export const fetchMoreEpisodesSuccess = (
  episodes: IEpisodeList
): types.EpisodesActionTypes => {
  return {
    type: types.FETCH_MORE_EPISODES_SUCCESS,
    payload: episodes,
  };
};
export const fetchMoreEpisodesFail = (
  error: any
): types.EpisodesActionTypes => {
  return {
    type: types.FETCH_MORE_EPISODES_FAIL,
    error: error,
  };
};

export function fetchEpisodes(podcastId: number) {
  const params = {
    page: 1,
    size: 10,
    query: "",
  };

  return (dispatch: Dispatch) => {
    dispatch(fetchEpisodesReset());
    dispatch(fetchEpisodesStart());
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/podcasts/${podcastId}/episodes`, {
        params: params
      })
      .then((res: any) => {
        dispatch(fetchEpisodesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchEpisodesFail(true));
      });
  };
}
export function fetchMoreEpisodes(
  podcastId: number,
  params: IPaginationRequest
) {
  return (dispatch: Dispatch) => {
    dispatch(fetchMoreEpisodesStart());
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/podcasts/${podcastId}/episodes`, {
        params: params
      })
      .then((res: any) => {
        dispatch(fetchMoreEpisodesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchMoreEpisodesFail(err));
      });
  };
}
