import React from 'react';
import './Error.scss';

const Error = (props:any) => {

    return(
        <div className="error">
    <p className={` notification ${props.className ? props.className : ''}`}>{props.message}</p>
            </div>
      
    );
}
export default Error;