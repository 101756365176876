import { AxiosResponse } from 'axios';
import { decode, encode } from 'jwt-simple';
import { Dispatch } from 'redux';
import axios from '../../shared/axios.interceptor';
import * as types from './types';
export const loginStart = (): types.LoginActionTypes => {
    return {
        type: types.LOGIN_START
    };
}
// reducer içindeki types 
export const loginSuccess = (token: string, userInfo: any, userRole?: any): types.LoginActionTypes => {
    return {
        type: types.LOGIN_SUCCESS,
        token: token,
        userInfo: userInfo,
        userRole: userRole
    };
}

export const loginFail = (error: any): types.LoginActionTypes => {
    return {
        type: types.LOGIN_FAIL,
        error: error
    };
}

export const logout = (): types.LoginActionTypes => {
    localStorage.removeItem('podcastStorage');
    return {
        type: types.LOGOUT
    };
}

export const loginRedirect = (redirectPath:string,token:string): types.LoginActionTypes => {
    return {
        type: types.LOGIN_REDIRECT,
        path:redirectPath,
        token:token
    };
}


export function login (fastLoginToken:string) {
    const bodyFormData = new FormData();
     bodyFormData.append('token', fastLoginToken);
     bodyFormData.append('type', 'podcaster');
    return (dispatch:Dispatch) => {
        dispatch(loginStart());
        axios.post(`${process.env.REACT_APP_BASE_URL}/fastlogin`,bodyFormData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        ).then((res:AxiosResponse) => {
            // console.log('login response................................',res);
                if(!res.data.agreementApproved) {
                    setLocalStorage (
                        res.headers.authorization,
                        null,
                        false,
                        res.data.roles?.[0]
                    );
                    dispatch(loginRedirect('/register',res.headers.authorization));
                } else {
                    setLocalStorage(
                        res.headers.authorization,
                        res.data.name,
                        true,
                        res.data.roles?.[0]
                    );
                    dispatch(loginSuccess(res.headers.authorization,res.data.name, res.data.roles?.[0]));
                }
            })
            .catch( err => {
                dispatch(loginFail(true));
            } );
    };
   
}
export function setLocalStorage(token: any, userInfo: any, agreementApproved: any, userRole: any) {
    const podcastStorage = {
        token: token,
        userInfo: userInfo,
        agreementApproved: agreementApproved,
        userRole: userRole
    };
    localStorage.setItem('podcastStorage', encode(podcastStorage, `${process.env.REACT_APP_LOGIN_SECRET_KEY}`));
}

// reducer'a objenin tamamını ayrıştırıp decoded olarak gönderiyor. 
export const authCheckState = () => {
    return (dispatch: Dispatch) => { 
        const podcastInfo = localStorage.getItem('podcastStorage') || null;
        // console.log(' ............ . . decode ........',podcastInfo);
        const decodedPodcastInfo = podcastInfo ? decode(podcastInfo, `${process.env.REACT_APP_LOGIN_SECRET_KEY}`) : null;
        if(!decodedPodcastInfo) {
            dispatch(logout());
        } else {
            if(decodedPodcastInfo.agreementApproved) {
                // let x = decodedPodcastInfo?.token?.split(' ');
                // let y = x[1] ? decode(x[1],'') : null;
                // console.log('*.*.*.*.**.*..**.* decodedPodcastInfo ...........', x, y);
                dispatch(loginSuccess(decodedPodcastInfo.token, decodedPodcastInfo.userInfo, decodedPodcastInfo.userRole));
            } else {
                dispatch(loginRedirect('/register', decodedPodcastInfo.token));
            }
        }
        
    }
};

export const getToken = () => {
    const podcastInfo = localStorage.getItem('podcastStorage') || null;
    const decodedPodcastInfo = podcastInfo ? decode(podcastInfo,`${process.env.REACT_APP_LOGIN_SECRET_KEY}`) : null;
    return decodedPodcastInfo ? decodedPodcastInfo.token : null;
}

