import React from 'react';
import { NavLink } from 'react-router-dom';
import { faqData } from '../../assets/statics/faq.json';
import Card from '../../components/UI/Card/Card';
import './FaqNew.scss';
class FaqNew extends React.Component {
    private param: any = "";
    props: any;
    match: any;
    state = {
        selectionIndex: -1,
        selected: {
            id: faqData[0].id,
            title: faqData[0].title,
            description: faqData[0].description,
            url: faqData[0].url,
        },
        contactSelected: false,
    }

    constructor(props: any) {
        super(props);
        this.param = this.props?.match?.params !== {} ? this.props?.match?.params?.param : '';

    }

    componentDidMount() {
        this.param = this.props?.match?.params !== {} ? this.props?.match?.params?.param : '';
        this.setParamsDynamically()
    }

    componentDidUpdate(prevProps: any) {
        this.param = this.props?.match?.params !== {} ? this.props?.match?.params?.param : '';
        if (prevProps.match.params?.param !== this.param) {
            this.setParamsDynamically();
        }
    }

    setParamsDynamically = () => {
        this.param = this.props?.match?.params !== {} ? this.props?.match?.params?.param : '';
        if (this.param !== '') {
            faqData.forEach(item => {
                if (item.url === this.param) {
                    this.setState({ selected: item })
                } else if (this.param === 'bize-ulasin') {
                    this.setState({ contactSelected: true });
                    this.setState({
                        selected: {
                            id: 99,
                            title: 'Bize Ulaşın',
                            description: '',
                            url: 'bize-ulasin'
                        }
                    })
                } else {

                }
            });
        } else {
            this.setState({ contactSelected: true });
            this.setState({
                selected: {
                    id: faqData[0].id,
                    title: faqData[0].title,
                    description: faqData[0].description,
                    url: faqData[0].url,
                }
            })
        }
    }

    changeDesc = (selection: any) => {
        if (this.state.contactSelected) {
            this.setState({ contactSelected: false });
        } else {
        }
        this.props.history.push('/yardim/' + selection.url)
        this.setState({ selected: selection })
    }

    render() {
        return <div id="faq-page" className="layout">
            <Card>
                <h1>Sık Sorulan Sorular</h1>
                <div className="row">
                    <div className="col-6">
                        <div className="titles">
                            {faqData.map((item) => (


                                <div className={"title " + (this.state.selected?.id === item.id ? 'strong' : '')} key={item.id} >
                                    <NavLink to={'/yardim/' + item.url}
                                        onClick={() => this.setState({ contactSelected: false })}
                                    >
                                        <div className="faq-title">{item.title}</div>
                                    </NavLink>
                                </div>

                            ))}
                            {
                                // Bize Ulaşın kısmına özel bir geliştirmedi. Eposta adresi tıklanabilir olması içindir
                                <div className={"title " + (this.state.contactSelected ? 'strong' : '')} key={99} >
                                    <NavLink to={'/yardim/bize-ulasin'} onClick={() => {
                                        this.setState({ contactSelected: true, selected: {} })
                                    }}>
                                        <div className="faq-title">Bize Ulaşın</div>
                                    </NavLink>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mt-5"></div>
                        <div className="description">
                            {
                                !this.state.contactSelected ?
                                    <div>
                                        <p className="title">{this.state.selected?.title}</p>
                                        <p className="desc">{this.state.selected?.description}</p>
                                    </div>
                                    :
                                    <div>
                                        <p className="title">Bize Ulaşın</p>
                                        <p>Podcaststudion için yardıma ihtiyacınız veya geri bildiriminiz vermek istediğinizde <a href="mailto:info@podcaststudion.com" target="_blank" rel="noopener noreferrer">info@podcaststudion.com</a> adresinden ulaşabilirsiniz.</p>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </Card>
        </div>
    }
}
export default FaqNew;