import { Dispatch } from "redux";
import axios from "../shared/axios.interceptor";
import { IPaginationRequest } from "../shared/type";
import { IPodcastList } from "./interfaces";
import * as types from "./types";
export const fetchPodcastsStart = (): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_PODCASTS_START
    };
}
export const fetchPodcastsReset = (): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_PODCASTS_RESET
    };
}
export const fetchPodcastsSuccess = (response: IPodcastList): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_PODCASTS_SUCCESS,
        payload: response
    };
}
export const fetchPodcastsFail = (error: any): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_PODCASTS_FAIL,
        error: error
    };
}


export const updatePodcastStart = (podcastId: number): types.UpdatePodcastActionTypes => {
    return {
        type: types.UPDATE_PODCAST_START,
        updatedPodcastId: podcastId
    };
}
export const updatePodcastReset = (): types.UpdatePodcastActionTypes => {
    return {
        type: types.UPDATE_PODCAST_RESET
    };
}
export const updatePodcastSuccess = (): types.UpdatePodcastActionTypes => {
    return {
        type: types.UPDATE_PODCAST_SUCCESS
    };
}
export const updatePodcastsSuccess = (podcastId: number, status: number): types.PodcastsActionTypes => {
    return {
        type: types.UPDATE_PODCASTS_SUCCESS,
        podcastId: podcastId,
        status: status
    };
}
export const updatePodcastFail = (): types.UpdatePodcastActionTypes => {
    return {
        type: types.UPDATE_PODCAST_FAIL
    };
}



export const fetchMorePodcastsStart = (): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_MORE_PODCASTS_START
    };
}
export const fetchMorePodcastsReset = (): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_MORE_PODCASTS_RESET
    };
}
export const fetchMorePodcastsSuccess = (podcasts: IPodcastList): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_MORE_PODCASTS_SUCCESS,
        payload: podcasts
    };
}
export const fetchMorePodcastsFail = (error: any): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_MORE_PODCASTS_FAIL,
        error: error
    };
}

export function fetchMorePodcasts(params: IPaginationRequest) {

    return (dispatch: Dispatch) => {
        dispatch(fetchMorePodcastsStart());
        axios.get(`${process.env.REACT_APP_BASE_URL}/podcasts`, {
            params: params
        }).then((res: any) => {

            dispatch(fetchMorePodcastsSuccess(res.data));
        })
            .catch(err => {
                dispatch(fetchMorePodcastsFail(err));

            });
    };
};

export function fetchPodcasts() {
    const params = {
        page: 1,
        size: 10,
        query: ''
    }
    return (dispatch: Dispatch) => {
        dispatch(fetchPodcastsReset());
        dispatch(fetchPodcastsStart());
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/podcasts`,
                { params: params }
            )
            .then((res: any) => {
                if (res.data) {
                    dispatch(fetchPodcastsSuccess(res.data));

                }
                else {
                    dispatch(fetchPodcastsFail(true));
                }

            })
            .catch(error => {
                dispatch(fetchPodcastsFail(true));
            });
    };
};

export function updatePodcast(podcastId: number, status: number) {

    return (dispatch: Dispatch) => {
        dispatch(updatePodcastReset());
        dispatch(updatePodcastStart(podcastId));
        axios.put(`${process.env.REACT_APP_BASE_URL}/podcasts/${podcastId}`,
            { userStatus: status }

        )
            .then((res: any) => {
                if (res.data && res.data.success) {
                    dispatch(updatePodcastSuccess());

                    dispatch(updatePodcastsSuccess(podcastId, status));
                } else {
                    dispatch(updatePodcastFail());

                }


            })
            .catch(err => {
                dispatch(updatePodcastFail());
            });
    };
}