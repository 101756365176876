
import React from 'react';
import './Home.scss';
class Home extends React.Component {

    render() {
        return (
           <div id="home-page" className="layout login">
                <div className="slogan">
                    <p>Podcast’ini Yükle</p>
                    <p>Seni Herkes Duysun </p>
                </div>
                <video playsInline autoPlay muted loop>
                <source src="https://dergilik.tcellcdn.net/cdn/podcast/landingVideo.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
                </video>
           </div>
              
        );
    }

}


  
export default Home;