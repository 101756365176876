import React from "react";
import './PrivacyAgreement.scss';
const PrivacyAgreement = () => {
  return (
    <div className="privacy contract-box">
      <div className="contract-box--content">
        <div className="contract-box--content-info">
          <h3>
            <strong> Podcast Yükleme ve Yayınlama Platformu Kullanım Koşulları </strong>
          </h3>
          <p>Geçerlilik tarihi: 13.10.2021</p>
          <h3> <strong>Podcast Yükleme ve Yayınlama Platformu Nedir? </strong></h3>
          <p>Podcast Yükleme ve Yayınlama Platformu (kısaca <strong> “Podcast Platformu” </strong>), Turkcell Grup Şirketleri [Turkcell İletişim Hizmetleri A.Ş. (“Turkcell”) ve Turkcell’in oy haklarına %50 veya üzerinde doğrudan veya dolaylı olarak sahip olduğu veya kontrol ettiği Türkiye’de ve/veya Türkiye dışında faaliyet gösteren şirketler ve/veya başkaca tüzel kişilerdir.] mecralarında yayınlanmak üzere, dijital medya içerikleri <strong>(“İçerikler”) </strong> yükleyebileceğiniz bir platformdur. Podcast Platformu’nu kullandığınız için teşekkürler! </p>
          <p><strong>Bu Sayfada Neler Var? </strong></p>
          <p> Bu sayfada yer alan Podcast Platformu Koşulları (kısaca “<strong>Koşullar</strong>”) Podcast Platformu’nun (“<strong>Hizmet</strong>”) internet sitesi ( <a target="_blank"  rel="noopener noreferrer" href="https://www.podcaststudion.com/">https://www.podcaststudion.com/</a> ) üzerinden kullanımına ilişkin temel bilgileri ve karşılıklı olarak uyulması konusunda sizinle anlaştığımız bazı kuralları içermektedir.</p>
          <p> Sayfada aşağı indikçe göreceğiniz başlıklar şöyle olacak: </p>
          <ul>
            <li> <strong>Platform Sahipliği Bilgileri </strong> </li>
            <li> <strong>Genel Şartlar </strong> </li>
            <li> <strong>Kullanım İlkeleri </strong> </li>
            <li> <strong>Fikri Mülkiyet Hakları, İçerikler ve Lisans </strong> </li>
            <li> <strong> Hizmette Yaşanabilecek Sorunlar ve Hukuki Uyuşmazlıklar</strong> </li>
          </ul>
          <p> Bu metni okumanın yorucu olabileceğinin farkındayız. Kısa ve öz bir anlatım kullanarak zahmetinizi olabildiğince azaltmaya çalışıyoruz. Bu Koşulları dikkatlice okumanızı tavsiye ederiz. Podcast Platformu’nu kullanabilmeniz için Koşulları kabul etmeniz gerekir. Yazılanların herhangi bir kısmını kabul etmiyorsanız Podcast Platformu’nu kullanmamanız gerekmektedir. Podcast Platformu’nu kullanarak bu koşulları kabul etmiş sayılırsınız. </p>
          <p> Turkcell Teknoloji, Koşullar’a uymadığınızı tespit eder veya bu yönde makul bir kanaat edinirse Podcast Platformu kullanımınızı durdurma hakkına sahiptir. Detaylı bilgi için aşağıdaki “Askıya Alma ve Fesih” başlığını inceleyebilirsiniz. </p>
          <p> Burada anlatılanlara ek olarak, Hizmet’in kullanımı amacıyla paylaştığınız ve kullanımınız sırasında oluşan kişisel verilerinizin işlenmesine ilişkin olarak bir aydınlatma metnimiz (Podcast Platformu Gizlilik Politikası) de bulunuyor. Kişisel verilerinizin kullanımı ile ilgili detayları burada bulabilirsiniz. </p>
          <p> <strong>Platform Sahipliği Bilgileri </strong></p>
          <p> Podcast Platformu, merkezi Aydınevler Mahallesi, İnönü Caddesi, Küçükyalı Ofispark No:36 Maltepe/İSTANBUL adresinde bulunan, İstanbul Ticaret Odası’na 707123-0 sicil numarasıyla tescilli, 4780187048 vergi numaralı Turkcell Teknoloji Araştırma ve Geliştirme A.Ş. (“<strong>Turkcell Teknoloji </strong>” ya da “<strong>biz</strong>”) tarafından sunulmaktadır.</p>
          <h3> <strong>Genel Şartlar </strong> </h3>
          <p> <strong>Yasal Ehliyet  </strong> Podcast Platformu’nu kullanabilmek için ülkenizdeki mevzuata uygun yasal ehliyete sahip olmanız gerekir. Eğer gerekli yasal ehliyete sahip değilseniz, Podcast Platformu’nu kullanmamanız gerekmektedir. </p>
          <p> <strong>Üyelik </strong> Podcast Platformu kullanımı için; üyelik oluşturmanız gerekmektedir. Üyelik ile ilgili detaylı bilgiyi “ <a target="_blank"  rel="noopener noreferrer" href="https://www.podcaststudion.com/"> https://www.podcaststudion.com/ </a> ” internet sitesinde bulabilirsiniz. </p>
          <p> Podcast Platformu’na Hızlı Giriş ile veya Turkcell Teknololji’nin belirleyeceği başka giriş yöntemleri ile giriş yapabilirsiniz. </p>
          <p> Turkcell Teknoloji, üyelik için aranan koşulları karşılamadığınızın anlaşılması halinde “Askıya Alma ve Fesih” başlığı altında yer alan fiillerden gerekli gördüklerini uygulayabilir. </p>
          <p> <strong>Hesabınız </strong> Podcast Platformu üzerinde bir hesap açmanız halinde, kullanıcı adı ve şifre bilgilerinizi gizli tutmanız ve herhangi bir üçüncü kişi ile paylaşmamanız gerekmektedir. Sizden başka birinin bu bilgilere sahip olduğunu düşünmeniz ya da bundan şüphelenmeniz durumunda, bizi derhal <a target="_blank"  rel="noopener noreferrer" href="mailto:info@podcaststudion.com"> info@podcaststudion.com </a> adresi üzerinden bilgilendiriniz. </p>
          <p> <strong>Değişiklikler ve düzeltmeler </strong> Turkcell Teknoloji, her zaman Koşullar’ın içeriğini düzelterek revize edebilir. Yaptığımız değişiklikleri öğrenmeniz için zaman zaman bu bölümü kontrol etmeniz gerekmektedir. Koşullar’a ilişkin yaptığımız değişikliğin ciddi önem arz etmesi halinde daha görünür (e-posta aracılığıyla veya Podcast Platformu üzerinden yapılacaklar dahil olmak üzere) bildirimler yapabiliriz.</p>
          <p> <strong>Devir </strong> Turkcell Teknoloji, her zaman için Podcast Platformu’nu ve/veya bu Koşullar kapsamındaki hak ve yükümlülüklerini, herhangi bir üçüncü kişi veya kuruluşa serbestçe ve size herhangi bir bildirimde bulunmaksızın devredebilir. Bu durum, bu Koşullar’da öngörülen şartlar kapsamında yerine getirmeniz gereken yükümlülüklerinizi etkilemez. Bu Koşullar’dan kaynaklanan hak, yükümlülük ve sorumluluklarınızı, her ne surette olursa olsun üçüncü şahıslara devir ve temlik edemezsiniz. </p>
          <p> <strong>Kullanım İlkeleri </strong> </p>
          <p> Bu bölüm, Koşullar’da yer alan diğer gerekliliklere ek olarak Podcast Platformu’nu kullanırken uymanız gereken diğer özel kuralları içermektedir.</p>
          <p> Podcast Platformu’nu kullanırken:</p>
          <ul>
            <li> hesap bilgileriniz kapsamında yanlış bilgi vermeyeceğinizi, hesabınızı başkasına kullandırmayacağınızı, rızası olmaksızın bir başkasının kimliği altında hareket etmeyeceğinizi ya da yanıltıcı olarak bir başkasının, kurumun ya da organizasyonun hesabına hareket ediyormuş izlenimi vermeyeceğinizi, </li>
            <li> Podcast Platformu’na hukuka, genel ahlaka ve toplumsal değerlere/düzene uygun olmayan, izinsiz ve haksız surette reklam veya tanıtım amaçlı vs. içerikler içeren, üçüncü kişilerin kişilik hakları, fikri ve sınai hakları dahil tüm haklarını ihlal eden, suç teşkil edecek ve bunlarla sınırlı olmaksızın ilgili mevzuat hükümlerinde ve/veya resmi mercii/kurul kararlarında belirtilen esaslara ve bu esaslara dayanılarak verilmiş kararlara uygun olmayan İçerikler yüklemeyeceğinizi, işbu maddede belirtilen koşullara aykırı İçerikler yüklemeniz, Kullanım İlkelerine aykırı davranmanız durumunda Koşullar’daki diğer taahhütleriniz saklı kalmak kaydı ile her türlü yasal ve hukuki sorumluluğun tümüyle tarafınıza ait olduğunu, İçerik kullanımının durdurulmasının ve/veya Podcast Platformu’ndaki hesabınızın kapatılması veya askıya alınması dahil İçerikler’i tamamen ve/veya kısmen Platform’dan çıkarma hakkının tümüyle Turkcell Teknoloji’nin inisiyatifinde olduğunu ve bu tür bir gerekçe ile İçerik kullanımının durdurulması halinde, Turkcell Teknoloji’nin tarafınıza her ne ad altında olursa olsun herhangi bir tazmin yükümlülüğü altına girmeyeceğini, bu durumda Turkcell Teknoloji’nin ve/veya Turkcell Grup Şirketleri’nin uğrayacağı tüm zarar ve ziyanı tazmin edeceğinizi,</li>
            <li> Podcast Platformu’nu kullanım amacıyla bağdaşması mümkün olmayacak şekilde, normal kabul edilmeyecek biçimde kullanmayacağınızı,</li>
            <li> Podcast Platformu’nun kullanımına imkân veren tüm bilgilerin kullanım ve yönetiminden bizzat sorumlu olduğunuzu, kendinize ait cihaz ve hesaplardan gerçekleştirilen her işlemin bizzat tarafınızca gerçekleştirilmiş addedileceğini ve bu bilgilerin doğrudan veya dolaylı surette kullanılması, kaybolması veya el değiştirmesi nedeniyle oluşacak tüm zararlardan münhasıran sorumlu olduğunuzu,</li>
            <li> Podcast Platformu’nu modifiye etmeyeceğinizi, bozmayacağınızı, hacklemeyeceğinizi, güvenlik ve İçeriklerin korunması için kullanılan özelliklerini dolanmayacağınızı ya da tersine mühendislik vb. yollarla Podcast Platformu’na müdahale etmeyeceğinizi, bu amaçla hareket eden üçüncü kişilere destek vermeyeceğinizi,</li>
            <li> bilinçli bir şekilde virüs, Truva atı programı (Trojans), kurt (worms), yazılım bombası (logic bomb), kod otomasyon aracı (script automation tool) ya da diğer benzer materyal yerleştirmek suretiyle Podcast Platformu’na ve Turkcell Teknoloji’e veya herhangi bir Podcast Platformu kullanıcısının donanımına zarar vermeyeceğinizi veya Podcast Platformu kullanıcı deneyimleri üzerinde olumsuz etki yaratmak amacıyla Podcast Platformu’nu suistimal etmeyeceğinizi, Podcast Platformu’na erişmek için herhangi bir otomasyon aracını veya işlevini kullanmayacağınızı (örneğin robot, örümcek, kazıyıcı vb.), Podcast Platformu’nu veri madenciliği vb. veri toplama amaçları için kullanmayacağınızı ve bu amaçla hareket eden üçüncü kişilere destek vermeyeceğinizi,</li>
            <li> Podcast Platformu’na diğer kullanıcıların yüklediği İçerikleri kopyalamayacağınızı, çoğaltmayacağınızı, İçerikler üzerinde haksız ve izinsiz surette hiçbir işlem yapmayacağınızı,</li>
            <li> Podcast Platformu’nu hiçbir şekilde suç teşkil edecek, yasal açıdan takip gerektirecek, ülke çapında ya da uluslararası düzeyde yasaların ve uluslararası anlaşmaların ihlali sonucunu doğuran ya da böyle durumlara teşvik eden, yasa dışı, tehditkâr, rahatsız edici, hakaret, küfür veya iftira içeren, küçük düşürücü, kaba, pornografik ya da kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edecek şekilde, kişilik haklarına zarar verici, başkalarının gizlilik hakkına veya özel hayatına veya mülkiyet haklarına tecavüz edecek şekilde kullanmayacağınızı,</li>
            <li> Turkcell Teknoloji’nin yukarıda sıralanan aykırı eylemleriniz dahil Koşullar’daki taahhüt ve yükümlülüklerinize aykırı davranmanız sebebiyle karşılaşacağı her türlü zarar ve ziyanı, hak sahiplerine ve/veya üçüncü kişilere ödenmek zorunda kalınacak tazminat, adli/idari para cezaları ve sair bedelleri ve yapılacak masrafları Turkcell Teknoloji’nin ilk talebi üzerine derhal tazmin etmekle yükümlü olduğunuzu, Turkcell Teknoloji’nin uğradığı ve/veya karşılamak zorunda kalacağı her türlü zarar ve ziyanın tarafınıza rücu edileceğini bildiğinizi</li>
            <li> İçeriklere ve Koşullar’daki taahhüt ve yükümlülüklerinize aykırı davranmanıza ilişkin üçüncü kişilerden gelebilecek gerek fikri hak gerekse sair talep/şikâyetler/dava nedeniyle tek sorumlu ve muhatap olduğunuzu, buna rağmen söz konusu talep/şikâyet/dava vb. nedeniyle Turkcell Teknoloji ve/veya Turkcell Grup Şirketleri nezdinde meydana gelebilecek her türlü zararı (maddi, manevi, doğrudan, dolaylı) derhal tazmin edeceğinizi, Turkcell Teknoloji’nin, Turkcell Teknoloji ve/veya Turkcell Grup Şirketleri’ne, İçeriklere ve sair taahhüt ve yükümlülüklerinize ilişkin herhangi bir şikayet gelmesi ve/veya fikri hakların ihlali durumunda, İçerikleri Podcast Platformu’dan ve/veya yayınlandığı mecralardan kaldırma hakkının saklı olduğunu, ayrıca Turkcell Teknoloji’nin böyle bir durumda Podcast Platformu hesabınızı kapatabileceğini veya askıya alabileceğini, bu durumda Turkcell Teknoloji ve/veya Turkcell Grup Şirketleri’nden herhangi bir talepte bulunmayacağınızı</li>
          </ul>
          <p> kabul ve taahhüt edersiniz.</p>
          <p> Podcast Platformu’nun kullanılabilmesi için internet bağlantısı gerekmekte olup, internet kullanımına ilişkin, anlaşmalı olduğunuz internet servis sağlayıcısının kullanım koşulları uygulanacak ve bu konuda Turkcell Teknoloji’nin herhangi bir sorumluluğu olmayacaktır.</p>
          <p> Podcast Platformu üzerinden sunulan Içeriklere erişim ve bunların kalitesi büyük ölçüde ilgili internet servis sağlayıcısından temin edilen hizmetin kalitesine dayanmakta olup, söz konusu hizmet kalitesinden kaynaklı sorunlarda Turkcell Teknoloji’nin herhangi bir sorumluluğu bulunmamaktadır. </p>
          <h3><strong> Fikri Mülkiyet Hakları, İçerikler ve Lisans </strong></h3>
          <p> <strong>Fikri Mülkiyet Hakları  </strong> Turkcell Teknoloji, Podcast Platformu sitesi, Podcast Platformu markaları, ticari adları, logoları, alan adları ve Podcast Platformu markasının diğer tüm unsurlarına ilişkin tüm fikri hakların maliki ya da kullanım hakkı sahibidir. Bahsi geçen haklar, dünyanın her yerinde telif yasaları ve uluslararası anlaşmalarla korunmaktadır.</p>
          <p><strong>Podcast Platformu’nu Kullanımınız İçin Size Verdiğimiz Lisans </strong> Bu Koşullar’ı kabul etmeniz kaydıyla size, Podcast Platformu’nu ticari olmayan ve bu Koşullar’a uygun amaçlarla kullanımınız için münhasır olmayan, devredilemez, iptal edilebilir, kullanım süresiyle sınırlı bir lisans veririz. Dolayısıyla, belirtilen lisans kapsamını aşar nitelikte davranmamanız gerektiğini, aksi takdirde hukuki sorumluluğunuzun doğabileceğini bildiririz.</p>
          <p> <strong>Kullanıcı İçeriği ve Bize Verdiğiniz Haklar </strong> </p>
          <p> İçeriklere ilişkin, 5846 sayılı Fikir ve Sanat Eserleri Kanunu md. 21’de düzenlenen işleme ve değiştirme hakkını, md. 22’de düzenlenen çoğaltma hakkını, md. 23’te düzenlenen yayma hakkını, md. 24’te düzenlenen temsil hakkını, md. 25’te düzenlenen umuma iletim hakkını ve İçerikler üzerinde/İçerikler ile ilgili borçlandırıcı ve tasarrufi işlem yapma, çeşitli şekil ve formatlarda kullanma, dijitalize etme, İçerikler’e ilişkin hakların kullanılması neticesinde gelir elde etme dahil sair tüm hakları ve İçerikler’i kullanım yetkisini; uydu, kablo diğer dijital, telli ve telsiz tüm araçlar olmak üzere ve bu sayılanlarla sınırlı olmaksızın her türlü mecrada (Turkcell Grup Şirketleri’ne ait tüm ürün/servis ve mecralar dahil), ürün ve malzemelerde vs.de geçerli olmak üzere,<strong> tüm dünyada </strong> kullanılabilir şekilde, <strong>sınırsız ve süresiz olarak</strong>, <strong>basit ruhsat şeklinde</strong>, <strong> Turkcell Grup Şirketleri’ne de kullandırılabilir bir şekilde </strong> Turkcell Teknoloji’ye vermiş olduğunuzu, bu madde kapsamında verdiğiniz haklar ve izinlerin FSEK madde 20/f.4 anlamında da muvafakat teşkil ettiğini, </p>
          <p> Ayrıca İçerikler’e ilişkin; </p>
          <p> (i) Tarafınızca ve üçüncü kişilerce telif veya her ne ad altında olursa olsun Turkcell Teknoloji ve/veya Turkcell Grup Şirketleri’nden maddi veya manevi hiçbir talep, iddiada bulunulmayacağını, herhangi bir hak sahibinin hakkının ihlal edilmediğini,</p>
          <p> (ii) İçerikler’in topluma sunuluş biçim, zaman ve yerinin Turkcell Teknoloji tarafından belirlenmesine muvafakat ettiğinizi,</p>
          <p> (iii) Turkcell Teknoloji tarafından gerçekleştirilecek tüm kullanımların 4721 sayılı Türk Medeni Kanunu ile FSEK kapsamında 14., 15. ve 16. maddelerinde sayılan manevi haklara ve kişilik haklarına tecavüz niteliğinde olmadığını ve bu kullanımlara şimdiden muvafakat ettiğinizi, </p>
          <p> (iv) İçerikler’in tamamen veya kısmen her türlü mecra/platform/portalde (basın, dergi, gazete, katalog, radyo, televizyon, sinema, internet, mobil, insert, poster, broşür ile tüm görsel, işitsel ve basılı mecralar ve tüm organizasyon, malzeme vs.) geçerli olmak üzere kullanılmasına, tanıtımının yapılmasına ve tanıtım amacıyla sınırlı kalmak kaydıyla, teknik olarak çeşitli şekil ve formatlarda kullanılmasına, İçerikler’in topluma sunuluş biçim, zaman ve yerinin Turkcell Teknoloji ve/veya Turkcell Grup Şirketleri tarafından belirlenmesine, tarafınıza haber verilmeksizin aynen ve Turkcell Teknoloji’nin tercihine bağlı olarak isminiz ile birlikte ya da isminiz olmaksızın kullanılmasına muvafakat ettiğinizi,</p>
          <p> (v) İçerikler’e ilişkin kullanım yetkisi verdiğiniz işbu Koşullar kapsamında yer alan tüm hakların Turkcell Grup Şirketleri’ne de kullandırılması halinde, işbu Koşullar tahtında Turkcell Teknoloji’ye verdiğiniz tüm hak ve yetkiler ile, taahhüt ve sorumluluklarınızın ilgili Turkcell Grup Şirketi’ne karşı da geçerli olduğunu,</p>
          <p> (vi) İçerikler’e ilişkin bağlantılı haklar da dahil olmak üzere her türlü hakkın sahibi olduğunuzu ve bu haklar üzerinde serbestçe tasarruf edebildiğinizi; İçerikler’e ilişkin işbu Koşullar’ı onaylamaya ve İçerikler ile ilgili taahhüt ve yükümlülükler ve işbu Koşullar’da belirtilen hakları Turkcell Teknoloji’ye vermeye münhasıran yetkili ve ehil olduğunuzu, Turkcell Teknoloji’ye verilen tüm haklar ve izinlerin FSEK madde 20/f.4 anlamında da muvafakat teşkil ettiğini; işbu Koşullar’ın, hak sahipleri ve/veya üçüncü kişilerle İçerikler’e ilişkin tarafınızca akdedilmiş sözleşmelere, mahkeme yahut idari ve icra organlarının kararlarına aykırı bulunmadığını ve bunları ihlal etmediğini; işbu Koşullar’ın ifasını etkiler nitelikte yazılı veya sözlü herhangi bir taahhüdünüzün olmadığını işbu Koşullar’ın ifası ile eser sahiplerinin, bağlantılı hak sahiplerinin ve meslek birlikleri dahil üçüncü kişilerin kişilik, mamelek, maddi ve manevi haklarının ihlal edilmediğini/edilmeyeceğini, İçerikler ile ilgili olarak herhangi bir uyuşmazlık ya da üçüncü kişilerin Turkcell Teknoloji’den ve/veya Turkcell Grup Şirketleri’nden hak talebi vuku bulduğu takdirde, Turkcell Teknoloji’ye ve/veya Turkcell Grup Şirketleri’ne yöneltilebilecek her türlü talep, iddia ve hukuki işlemlerin tek muhatabı olduğunuzu ve bunlarla ilgili Turkcell Teknoloji’nin ve/veya Turkcell Grup Şirketleri’nin uğrayacağı her türlü zararı talep hakkının saklı olduğunu bildiğinizi,</p>
          <p> (vii) İşbu Koşullar ile Turkcell Teknoloji’ye tanınan haklar kapsamında, Turkcell Teknoloji’den ve/veya Turkcell Grup Şirketleri’nden her ne ad altında olursa olsun bedel talep etmeyeceğinizi, Turkcell Teknoloji’nin ve/veya Turkcell Grup Şirketleri’nin İçerikler’i ticarileştirip gelir elde edebileceğini, bu kapsamda Turkcell Teknoloji’yi ve Turkcell Grup Şirketleri’ni gayri kabili rücu ibra ettiğinizi</p>
          <p> kabul ve taahhüt edersiniz.</p>
          <p> <strong>İçerik İhlalleri </strong> İçeriklerin sizin ya da temsilcisi olduğunuz üçüncü bir tarafın haklarını veya Kullanım İlkeleri’ni ihlal ettiğini düşünüyorsanız, lütfen <a target="_blank"  rel="noopener noreferrer" href="mailto:info@podcaststudion.com"> info@podcaststudion.com</a> adresi üzerinden e-posta göndererek bizlere bilgi veriniz.</p>
          <p> Bizlere bu bağlamda bildirim gönderirken, gönderdiğiniz e-postada şu bilgilere yer vermenizi rica ederiz:</p>
          <p> (a) isminiz ve iletişim bilgileriniz,</p>
          <p> (b) İhlal oluşturduğuna inanılan içeriğin şunlardan hangisini ihlal ettiği: (i) sizin haklarınızı, (ii) temsilcisi olduğunuz üçüncü bir kişinin haklarını (iii) ya da Kullanım İlkeleri’ni, ve</p>
          <p> (c) İhlal edildiği ileri sürülen hakkın tanımı, açıklaması ve kime ait olduğu. </p>
          <p> Turkcell Teknoloji, bildirmiş olduğunuz içeriğin mahiyetine uygun bulduğu şekilde uygun gördüğü işlemleri gerçekleştirecek ya da önlemleri alacaktır. Bu, Turkcell Teknoloji tarafından mevzubahis içeriğin herhangi bir kimsenin haklarını ya da Kullanım İlkeleri’ni ihlal etmediğine karar verilmesi halinde herhangi bir işlem gerçekleştirmemeyi de kapsar. Turkcell Teknoloji, göndermiş olduğunuz bildirimi müteakiben gerçekleştirmeyi seçmiş olduğu işlemi ya da işlemleri müzakere etmek ya da sizleri bu hususlar hakkında bilgilendirmekle yükümlü değildir. </p>
          <p> Turkcell Teknoloji, Kullanım İlkeleri’ni ihlal eden kullanıcıların hesaplarını askıya alma ve fesih yetkisine sahiptir. Bildirim kanallarını kötüye kullanmanız durumunda da benzer sonuçlarla karşılaşma olasılığınız bulunmaktadır. Talebiniz kapsamında gönderdiğiniz isim, e-posta adresi vb. diğer tüm bilgiler yayından kaldırma talebinizin içeriğine dâhildir ve şikâyet edilen taraf da dâhil olmak üzere ilgili kişilerle paylaşılabilir. Kişisel verilerinizin işlenmesine ilişkin detaylı bilgiyi Podcast Platformu Gizlilik Politikası’nda bulabilirsiniz. </p>
          <p> <strong>Hizmette Yaşanabilecek Sorunlar ve Hukuki Uyuşmazlıklar </strong> </p>
          <p> <strong>Askıya Alma ve Fesih  </strong> Podcast Platformu’nun yukarıda sayılan kullanım ilkeleri ile bağdaşmayan ve size verilen lisans kapsamını aşar şekilde kullanımı, Kullanım Koşulları’nın ciddi bir şekilde ihlalini teşkil eder ve tarafımızca sayılan şu fiillerden birinin ya da tamamının gerçekleştirilmesine</p> <p> sebebiyet verebilir:</p>
          <p> (a) derhal, geçici ya da sürekli bir biçimde Podcast Platformu’nu kullanma hakkınıza son verilmesi ve ilgili İçeriklerin silinmesi,</p>
          <p> (b) ihlalden dolayı ortaya çıkabilecek tüm zararların tarafınızca tazmini ve/veya</p>
          <p> (c) gerekli görüldüğü surette ve ölçüde söz konusu ihlale ilişkin bilginin yetkili makamlarla paylaşılması</p>
          <p> İhlal durumunda yapılabilecekler, yukarıda sayılanlarla sınırlı olmayıp Turkcell Teknoloji tarafından uygun görüldüğü sürece başkaca yaptırımlar uygulanabilecek ve yasal yollara başvurulabilecektir. Bu nedenle Turkcell Teknoloji’ten herhangi bir hak talebinde bulunmayacağınızı kabul edersiniz.</p>
          <p> <strong>Hizmetin kesilmesi veya değişmesi </strong> Turkcell Teknoloji, Podcast Platformu’nun düzgün çalışması için makul çaba sarf edecektir. Ancak belirli durumlarda (mücbir sebepler, teknik sorunlar, bakım, test veya hukuki gerekliliklerin uygulamaya yansıtılması için yapılacak çalışmalar vs.) yapılması gereken güncellemeler sebebiyle geçici kesintiler söz konusu olabilecektir. Turkcell Teknoloji, her zaman, herhangi bir ön bildirim yapmaksızın ve rıza almaksızın Podcast Platformu’nu ve/veya İçerikleri kısmen veya tamamen, herhangi bir adli ya da idari merciinin talebi üzerine ya da takdir hakkı kendine ait olmak üzere, herhangi bir gerekçe göstermeksizin geçici veya kalıcı olarak sansürleme, değiştirme, durdurma, yayından kaldırma veya hiç yayınlamama hakkına sahiptir. Böyle bir durumda, Turkcell Teknoloji’den her ne nam altında olursa olsun herhangi bir hak/alacak talebinde bulunmayacağınızı kabul edersiniz. </p>
          <p> <strong>Sorumluluğun Reddi </strong> Turkcell Teknoloji, herhangi bir zamanda herhangi bir uyarı yapmaksızın Podcast Platformu’na erişimi kısıtlayabilme hakkını saklı tutar. Turkcell Teknoloji, Podcast Platformu’nun herhangi bir zamanda ve herhangi bir süreyle erişilebilir olmamasından, Podcast Platformu’nu kullanmanız esnasında karşılaşabileceğiniz teknik problemler, hatalar veya eksikliklerden hiçbir surette mesul olmayacaktır. Böylesi bir hatadan haberdar edilmesi durumunda, Turkcell Teknoloji söz konusu hatanın en kısa sürede düzeltilmesi için makul gayret gösterecektir. </p>
          <p> Podcast Platformu kapsamındaki hizmeti kısmen veya tamamen, geçici veya daimi olarak durduracak ve/veya imkansız kılacak şekilde ve derecede meydana gelen doğal afetler, harp, seferberlik, yangın halleri, hükümet ve idari makam kararları ile benzeri haller mücbir sebep sayılır. Bu gibi hallerde Turkcell Teknoloji’nin yükümlülüklerini yerine getirmemesi sebebiyle size karşı bir sorumluluğu söz konusu olmayacaktır.</p>
          <p> Turkcell Teknoloji, Podcast Platformu kapsamındaki hizmet ile ilgili olarak herhangi bir taahhütte bulunmamakta, koşulsuz ya da garantisiz bir biçimde ve hizmeti “olduğu gibi” sunmaktadır. Turkcell Teknoloji, Podcast Platformu kullanımından kaynaklanan herhangi bir kayıp veya zarardan (doğrudan veya dolaylı) sorumlu değildir. Bununla birlikte, her halükarda Turkcell Teknoloji’in Podcast Platformu’nun kullanımı veya Podcast Platformu ile bağlantılı her türlü taleple ilgili olarak size veya yasal varislerinize karşı toplam sorumluluğu, Podcast Platformu kullanımı dolayısıyla Turkcell Teknoloji’e son oniki ayda ödemiş olduğunuz meblağla sınırlıdır.</p>
          <p><strong>Bölünebilirlik </strong> Bu Koşullar’dan herhangi birinin geçersiz sayılması durumunda diğer hüküm ve şartlar, hukuken mümkün olduğu surette geçerliliklerini koruyacaklardır.</p>
          <p><strong>Yargı ve Uygulanacak </strong> <strong>Hukuk </strong> Podcast Platformu’na ilişkin her türlü uyuşmazlık kanunlar ihtilafı kuralları dikkate alınmaksızın münhasıran Türk hukukuna tâbi olarak İstanbul Anadolu Adliyesi mahkemeleri ve İcra Daireleri tarafından çözümlenecektir. Bununla birlikte, Turkcell Teknoloji, işbu Koşullar’ın ihlali nedeniyle ikamet ettiğiniz ülkede veya ilgili bir üçüncü ülkede dava açma hakkını saklı tutar. Podcast Platformu’nun tarafınızca kullanımı ile irtibatlı ihtilaflarda, elektronik ve sistem kayıtları da dahil olmak üzere Turkcell Teknoloji kayıtları Hukuk Muhakemeleri Kanunu m. 193 kapsamında geçerli, bağlayıcı, kesin ve münhasır delil teşkil edecektir.</p>
          <p><strong>Bize ulaşın </strong> Podcast Platformu konusunda bizlere geri bildirim sağlamak isterseniz, <a target="_blank"  rel="noopener noreferrer" href="mailto:info@podcaststudion.com"> info@podcaststudion.com </a> e-posta adresi üzerinden bize ulaşabilirsiniz.</p>
        </div>
      </div>
    </div >
  );
};
export default PrivacyAgreement;