import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import clickOutside from '../../../store/shared/hooks';
import './Modal.scss';
const Modal = (props:any) => {
  const ref:any = useRef();
  const deb = _.debounce( props.onClose, props.delay);
  useEffect(() => {
   if(props.delay ) {
    deb();
   } 
  },[props.delay,deb]);
  clickOutside(ref, () => {
    if(props.clickedOutside) {
      props.onClose();

    }
  });
 

return props.show &&
<div className={`modal ${props.className}`}>
<div className="modal__wrapper">
  <div className={`modal__body ${props.bodyClass ? props.bodyClass : ''}`} ref={ref}>
    <div className="modal__title">
      {props.title}
    </div>
    <div className={`modal__content ${props.sencondClass ? props.sencondClass : ''}`}>
      {props.children}
    </div>
  </div>
</div>
</div> ;
}
export default Modal; 

