import React from 'react';
import ReactTooltip from 'react-tooltip';
import image from '../../../assets/images/icons/question-mark.png';
import './Tooltip.scss';
const Tooltip = (props: any) => {

    return (
        <div className="custom-tooltip">
            <img alt="" data-for="tooltip" data-tip src={image} style={{ width: props.width }} />
            <ReactTooltip id={props.id ? props.id : "tooltip"} type="light" effect="float" >
                {props.text}
            </ReactTooltip>

        </div>
    )
}
export default Tooltip;