import React from 'react';
import './Button.scss';
const Button = (props:any) => {
return (
    <button className={`button ${props.className ? props.className : ''}`}
            disabled={props.disabled}
            onClick={props.onClick}
            >
            <div className="btn-text">{props.text}</div>
            
    </button>
)
} 
export default Button;