import React from 'react';
import { Link } from 'react-router-dom';
import fastLoginLogo from '../../../assets/images/logos/fast-login.png';
import Logo from '../../Logo/Logo';
import Menu from '../Menu/Menu';
import './Header.scss';
const Header = (props: any) => {
    // let history = useHistory();
    const role = props.userRole;
    const logoOnClick = () => {
        // console.log(props.userRole)
        if (role?.toString().toLowerCase() === "podcastmoderator") {
            return props.isAuth ? window.location.href = '/podcast-moderation' : window.location.href = '/';
        } else {
            return props.isAuth ? window.location.href = '/my-podcasts' : window.location.href = '/';
        }
    }
    return (
        <header id="header">
            <div className="header-left">
                <Logo onClick={logoOnClick} />
            </div>
            <div className="header-right">
                {
                    props.isAuth
                        ?
                        <div className="btn-container">
                            {
                                props.isAuth && (!props.isModerator)
                                    ?
                                    <div className="mypodcast-btn">
                                        <Link to="/my-podcasts">Podcastlerim</Link>
                                    </div>
                                    : null
                            }
                            <Menu userName={props.userName} />
                        </div>
                        :
                        <div className="btn-container">
                            <div className="sss-btn">
                                <Link to="/yardim">Sık Sorulan Sorular</Link>
                            </div>
                            <div className="fast-login-btn" onClick={props.onClick} >
                                <img alt="" src={fastLoginLogo} />
                            </div>
                        </div>
                }
            </div>

        </header>
    );
};
export default Header;