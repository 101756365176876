import React from 'react';
import { dateFormat } from '../../../store/shared/utility';
import Button from '../../UI/Button/Button';
import './PodModeration.scss';
import '../../../components/UI/Modal/Modal.scss';

const PodModeration = (props: any) => {

    const modalAction = (value: number, podcast: any, modal: boolean) => {
        props.modalAction(value, podcast, modal, 'podcast')
    }

    const checkImageSource = (url?: any) => {
        const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

        /** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
        const DATA_URL_PATTERN = /^data:(?:image(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video(?:mpeg|mp4|ogg|webm)|audio(?:mp3|oga|ogg|opus));base64,[a-z0-9+]+=*$/i;

        if (url === "null" || url === null || url === '' || url.length === 0 || url === "about:blank") {
            return null;
        };
        if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) {
            const image = React.createElement("img", { src: url, alt: '' });
            return image;
        };

        return null;
    }

    return (
        <div>
            <div className="mo-podcast">
                <div className={`podcast--content ${props.className}`} >
                    <i className="icon-triangle"></i>
                    <div className="podcast--info img-box">
                        {checkImageSource(props.podcast.imageUrl)}
                    </div>
                    <div className="podcast--info w20">
                        <span className="podcast--title">Kategori</span>
                        <span className="podcast--text">{props.podcast.category}</span>
                    </div>
                    <div className="podcast--info w20">
                        <span className="podcast--title">Yayıncı</span>
                        <span className="podcast--text">{props.podcast.author}</span>
                    </div>
                    <div className="podcast--info w20">
                        <span className="podcast--title">Başlık</span>
                        <span className="podcast--text">{props.podcast.title}</span>
                    </div>
                    <div className="podcast--info w20">
                        <span className="podcast--title">Güncellenme</span>
                        <span className="podcast--text">{dateFormat(props.podcast.lastUpdate || '')}</span>
                    </div>

                    <div className="podcast mod-buttons">
                        <Button
                            text={props.podcast.approveStatus === 1 ? 'Onaylandı' : 'Onayla'}
                            disabled={props.podcast.approveStatus === 1}
                            className={props.podcast.approveStatus === 1 ? 'moderation-button confirm confirmed' : 'moderation-button confirm'}
                            onClick={() => modalAction(1, props.podcast, true)}>
                        </Button>
                        <Button
                            text={props.podcast.approveStatus === 2 ? 'Reddedildi' : 'Reddet'}
                            disabled={props.podcast.approveStatus === 2}
                            className={props.podcast.approveStatus === 2 ? 'moderation-button reject rejected' : 'moderation-button reject'}
                            onClick={() => modalAction(2, props.podcast, true)}>
                        </Button>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    );

}
export default PodModeration;