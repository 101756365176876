import {LoginActionTypes, LoginState, LOGIN_FAIL, LOGIN_REDIRECT, LOGIN_START, LOGIN_SUCCESS, LOGOUT,} from './types'; 

const loginInitialState : LoginState = {
    token: null,
    userInfo:null,
    loading: false,
    error:false,
    redirectPath:'/my-podcasts',
    agreementApproved: true,
    userRole: null
};

const reducer = (
    state = loginInitialState,
    action: LoginActionTypes
  ): LoginState =>{

    switch (action.type) {
        case LOGIN_START:
            return {
                ...loginInitialState,
                loading:true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                agreementApproved:true,
                token:action.token,
                userInfo:action.userInfo,
                loading: false,
                userRole: action.userRole
            }
        case LOGIN_FAIL:
            return {
                ...state,
                error:true,
                loading:false
            }
        case LOGIN_REDIRECT:
            return {
                ...state,
                agreementApproved:false,
                redirectPath:action.path,
                token:action.token,
                loading:false
            }
        case LOGOUT:
        return {
            ...loginInitialState
        }
        default:
        return state;
    }
  }

const loginReducer = {
    loginStore:reducer
};

export default loginReducer;