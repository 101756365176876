import React, { useEffect, useState } from 'react';
import { IPodcast } from '../../store/podcasts/interfaces';
import { statusFormat } from '../../store/shared/utility';
import Dropdown from '../UI/Dropdown/Dropdown';
import Tooltip from '../UI/Tooltip/Tooltip';
import './Podcast.scss';

const Podcast = (props: any) => {
    const [selectionVal, setSelectionVal] = useState({ key: -1, value: '' });
    const options = [{ key: 0, value: 'Pasif' }, { key: 1, value: 'Aktif' }];

    useEffect(() => {
        if ((props.podcast.userStatus === 0 || props.podcast.userStatus === 1) && props.podcast.process === 2) {
            if (props.podcast.approveStatus === 2) {
                setSelectionVal({ key: -1, value: '' });
            } else {
                const selectedVal = options.find((op: any) => op.key === props.podcast.userStatus) || options[0];
                setSelectionVal(selectedVal);
            }
        }
    }, [props]);

    const calculateListenDuration = (duration: any = 0) => {
        duration = duration >= 0 ? duration : 0;
        if (duration > 0) {
            return Math.round(duration / 60) + 'dk';
        } else {
            return 0;
        }
    }

    const isUpdatable = (podcast: IPodcast) => {
        return statusFormat(podcast);
    }
    const selectionChange = (selectedVal: any, podcastId: number) => {
        props.selectionChange(podcastId, selectedVal.key);

    }
    const checkImageSource = (url: any) => {
        const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

        /** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
        const DATA_URL_PATTERN = /^data:(?:image(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video(?:mpeg|mp4|ogg|webm)|audio(?:mp3|oga|ogg|opus));base64,[a-z0-9+]+=*$/i;

        if (url === "null" || url === null || url === '' || url.length === 0 || url === "about:blank") {
            return null;
        };
        if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) {
            const image = React.createElement("img", { src: url, alt: '' });
            return image;
        };

        return null;
    }
    return (
        <div className="podcast">
            <div className="podcast-content  main-container">
                <div className={`podcast--content ${props.className}`} >
                    <i className="icon-triangle"></i>
                    <div className="podcast--info img-box">
                        {checkImageSource(props.podcast.imageUrl)}
                    </div>
                    <div className="podcast--content inner-container">
                        <div className="podcast--content block-inner col-12 col-xs-6 col-md-3">

                            <div className="podcast--info col-md-6 col-12">
                                <span className="podcast--title">Yayıncı</span>
                                <span className="podcast--text">{props.podcast.author}</span>
                            </div>
                            <div className="podcast--info col-md-6 col-12">
                                <span className="podcast--title">Başlık</span>
                                <span className="podcast--text">{props.podcast.title}</span>
                            </div>
                        </div>
                        <div className="podcast--content block-inner col-12 col-xs-6 col-md-3">
                            <div className="podcast--content inner col-xs-12 col-md-6">
                                <div className="podcast--info">
                                    <span className="podcast--title">Durum</span>
                                    <span className={`podcast--text ${isUpdatable(props.podcast)?.className}`}>{isUpdatable(props.podcast)?.text}</span>
                                </div>
                                <div className="podcast--info mw-70">
                                    <span className="podcast--title">Dinleme <Tooltip width="13px" text="Bir kullanıcı, podcastindeki herhangi bir bölümü dinlemeye başladığı 0. saniye ölçülür." /></span>

                                    <span className="podcast--text">{props.podcast.listenCount}</span>
                                </div>

                            </div>
                            <div className="podcast--content inner col-xs-12 col-md-6">
                                <div className="podcast--info">
                                    <span className="podcast--title">Süre</span>

                                    <span className="podcast--text">{calculateListenDuration(props.podcast.totalListenDuration)}</span>
                                </div>
                                <div className="podcast--info">
                                    <span className="podcast--title">Aksiyon</span>
                                    <Dropdown
                                        selectionChange={(selectedVal: any) => selectionChange(selectedVal, props.podcast.id)}
                                        options={options}
                                        disabled={!isUpdatable(props.podcast)?.status}
                                        selectedOption={selectionVal} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );

}
export default Podcast;