import  axios,{ AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { getToken, setLocalStorage } from '../login/actions';
import * as types from './types';
export const fetchAgreementStart = (): types.AgreementActionTypes => {
    return {
        type: types.FETCH_AGREEMENT_START
    };
}

export const fetchAgreementSuccess = (payload:any): types.AgreementActionTypes => {
    return {
        type: types.FETCH_AGREEMENT_SUCCESS,
        payload:payload
    };
}

export const fetchAgreementFail = (error: any): types.AgreementActionTypes => {
    return {
        type: types.FETCH_AGREEMENT_FAIL,
        error: error
    };
}

export const registerStart = (): types.RegisterActionTypes => {
    return {
        type: types.REGISTER_START
    };
}
export const registerSuccess = (payload:any): types.RegisterActionTypes => {
    return {
        type: types.REGISTER_SUCCESS,
        payload:payload
    };
}
export const registerFail = (error:any): types.RegisterActionTypes => {
    return {
        type: types.REGISTER_FAIL,
        error:error
    };
}
export function fetchAgreement () {
    
    return (dispatch:Dispatch) => {
        dispatch(fetchAgreementStart());
        axios.get(
        `${process.env.REACT_APP_BASE_URL}/podcast/agreement`
        ).then((res:AxiosResponse) => {
            if(res.data.uuid)  {
                dispatch(fetchAgreementSuccess(res.data));
            } else {
                dispatch(fetchAgreementFail(true));

            }
        }).catch( err => {
            dispatch(fetchAgreementFail(true));
        } );
    };
   
}

export function register (firstName:string, lastName:string,agreementUuid:any) {
    return (dispatch:Dispatch) => {
        dispatch(registerStart());
        axios.post(`${process.env.REACT_APP_BASE_URL}/podcast/agreement`,{
            uuid:agreementUuid,
            firstname:firstName,
            lastname:lastName
        },{
            headers:{
                Authorization:`${getToken()}`
            }
        }
        ).then((res:AxiosResponse) => {
            // console.log('check, register func, api/podcast/agreement', res, '..... role data: ',res.data.roles?.[0]);
            setLocalStorage(
                getToken(),
                `${firstName} ${lastName}`,
                true,
                res.data.roles?.[0]
            );
            dispatch(registerSuccess(true));
        }).catch( err => {
    
            dispatch(registerFail(true));
        } );
    };
}