import React from 'react';
import logo from '../../assets/images/logos/logo.png';
import './Logo.scss';

const Logo = (props:any) => (
<div className="app-logo" onClick={props.onClick}>
<img src={logo} alt="" />
</div>
);
export default Logo;
