import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { logout } from '../../../store/auth/login/actions';
type Props = 
ReturnType<typeof mapDispatchToProps> ;
class Logout extends React.Component<Props> {
  
    componentDidMount() {
        this.props.onLogout();
    }

    render () {
        return <Redirect to="/"/>;
    }
}

const mapDispatchToProps = (dispatch:Dispatch) => {
    return {
        onLogout: () => dispatch(logout())   
     }
};

export default connect(null, mapDispatchToProps)(Logout);