import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Navigation/Header/Header';
import Auxil from '../Auxil/Auxil';


type OwnProps = {
    children: React.ReactNode,
    onClick?: any,
};
type Props = ReturnType<typeof mapStateToProps> & OwnProps;
class Layout extends Component<Props> {

    render() {
        const role = this.props.userRole;
        const isModerator = role?.toString().toLowerCase() === "podcastmoderator" || role?.toString().toLowerCase() === "moderator";
        // console.log('menü gösterimi için kontrol noktası .................', isModerator, '......', role);

        return (
            <Auxil>
                <Header
                    userName={this.props.userName}
                    isAuth={this.props.isAuthenticated}
                    onClick={this.props.onClick}
                    isModerator={isModerator}
                />
                {/* 
                    login olmuş 
                    VE
                        isLoading true ise
                        VEYA
                        isLoading false ise VE podcasti olduğu kesinleşmiş ise TabMenu göster
                            değilse null (gösterme)
                */}

                <main id="content">
                    {this.props.children}
                </main>
            </Auxil>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.loginStore.token && state.loginStore.agreementApproved,
        userName: state.loginStore.userInfo,
        isLoginBtnDisplay: state.loginStore.token ? true : false,
        // podcastLength: state.podcasts.podcasts.content.length,
        isLoading: state.podcasts.loading,
        userRole: state.loginStore.userRole,
    };
};
export default connect(mapStateToProps)(Layout);
