import React from 'react';
import {
    Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel
} from "react-accessible-accordion";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import Episode from '../../components/Episode/Episode';
import Error from '../../components/Error/Error';
import Podcast from '../../components/Podcast/Podcast';
import Button from '../../components/UI/Button/Button';
import Card from '../../components/UI/Card/Card';
import Spinner from '../../components/UI/Spinner/Spinner';
import VirtualScroll from '../../components/UI/VirtualScroll/VirtualScroll';
import { fetchEpisodes, fetchMoreEpisodes } from '../../store/episodes/actions';
import { IEpisode } from '../../store/episodes/interfaces';
import { EpisodesActionTypes } from '../../store/episodes/types';
import { fetchMorePodcasts, fetchPodcasts, updatePodcast } from '../../store/podcasts/actions';
import { IPodcast } from '../../store/podcasts/interfaces';
import { PodcastsActionTypes } from '../../store/podcasts/types';
import './Podcasts.scss';

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & any;

class Podcasts extends React.Component<Props> {

    state = {
        podcastPaginationParams: {
            page: 1,
            size: 10,
            query: ''
        },
        episodePaginationParams: {
            page: 1,
            size: 10,
            query: ''
        },
        podcastSelectionIndex: 0,
        initialSelection: true,
        podcastSelectionId: 0
    }

    componentDidMount() {
        this.props.fetchPodcasts();
    }
    componentDidUpdate(prevProps: any) {
        if (this.props.podcastsContent.length > 0 && this.state.initialSelection) {
            this.props.fetchEpisodes(this.props.podcastsContent[0].id);
            this.setState({ initialSelection: false, podcastSelectionId: this.props.podcastsContent[0].id });
        }
        if (prevProps.podcasts !== this.props.podcasts) {
            if (!this.props.podcasts.loading && !this.props.podcasts.error && this.props.podcastsContent.length === 0) {
                this.props.history.push('/no-podcast');
            }
        }
    }



    togglePodcast = (selectedIndex: number, podcastId: number) => {
        const selection = this.state.podcastSelectionIndex === selectedIndex ? -1 : selectedIndex;
        this.setState({
            podcastSelectionIndex: selection
        });
        if (selection > -1) {
            this.setState({ podcastSelectionId: podcastId })
            this.props.fetchEpisodes(podcastId);
        }

    }
    updatePodcastStatus = (podcastId: number, updatedStatus: number) => {
        this.props.updatePodcast(podcastId, updatedStatus)
    }
    hasMorePodcasts = () => {
        if (this.props.podcastsContent.length > 0) {
            return this.props.podcastsContent.length >= this.props.podcasts.podcasts.totalElements ? false : true;
        } else {
            return false;
        }
    }
    fetchMorePodcasts = () => {
        const currentPage = this.state.podcastPaginationParams;
        currentPage.page = (currentPage.page + 1);
        this.setState({ podcastPagination: currentPage });
        this.props.fetchMorePodcasts(currentPage);
    }

    fetchMoreEpisodes = () => {
        const currentPage = this.state.episodePaginationParams;
        currentPage.page = (currentPage.page + 1);
        this.setState({ episodePaginationParams: currentPage });
        this.props.fetchMoreEpisodes(this.state.podcastSelectionId, currentPage);
    }

    podcastOverlay = (updatePodcastId: number) => {
        return this.props.updatedPodcast.loading && this.props.updatedPodcast.updatedPodcastId === updatePodcastId;
    }
    createEpisodes = () => {

        if (this.props.episodes.loading) {
            return <Spinner />
        } else if (this.props.episodes.error) {
            return <Error className="center" message={this.props.episodes.message} />
        } else {
            if (this.props.episodesContent.length === 0) {
                return <Error className="center" message={'Podcaste ait episode kaydı bulunamadı.'} />
            } else {
                return <AccordionItemPanel className="episodes" onClick={(event) => event.stopPropagation()}>
                    <VirtualScroll
                        maxHeight="400px"
                        endMessage={true}
                        hasMore={this.props.episodesContent.length >= this.props.episodes.episodes.totalElements ? false : true}
                        length={this.props.episodesContent.length} fetchMore={this.fetchMoreEpisodes}
                        totalElements={this.props.episodes.episodes.totalElements}>
                        {
                            this.props.episodesContent.map((episode: IEpisode, index: number) =>
                                <Episode key={index} episode={episode} />
                            )
                        }
                    </VirtualScroll>
                </AccordionItemPanel>;
            }
        }


    }
    createPodcasts = () => {

        if (this.props.podcasts.loading) {
            return <Spinner />
        } else {
            if (this.props.podcasts.error) {
                return <Error message={this.props.podcasts.message} />
            } else {

                return <VirtualScroll
                    maxHeight="100%"
                    endMessage={false}
                    hasMore={this.hasMorePodcasts()}
                    length={this.props.podcastsContent.length} fetchMore={this.fetchMorePodcasts}
                    totalElements={this.props.podcasts.podcasts.totalElements}
                >

                    {this.props.podcastsContent && this.props.podcastsContent.map((podcast: IPodcast, index: number) =>

                        <AccordionItem

                            key={index}
                            uuid={index.toString()}
                            onClick={() => this.togglePodcast(index, podcast.id)}
                            className={`podcasts ${this.podcastOverlay(podcast.id) ? 'overlay' : ''}`}>
                            {this.podcastOverlay(podcast.id) ? <Spinner className="overlay" /> : null}

                            <AccordionItemHeading >
                                <AccordionItemButton className="podcast--button">
                                    <Podcast
                                        className={this.state.podcastSelectionIndex === index ? 'active' : ''}
                                        podcast={podcast}
                                        selectionChange={this.updatePodcastStatus}
                                    />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            {this.state.podcastSelectionIndex === index ? this.createEpisodes() : null}
                        </AccordionItem>
                    )}
                </VirtualScroll>
            }


        }
    }

    render() {
        return <div className="layout" id="podcasts-page">
            <Card>
                <div className="title-area">
                    <h1>Podcastlerim</h1>
                    <Link to="/podcast-create" >
                        <Button icon="plus" text="Podcast Ekle" />
                    </Link>
                </div>
                <Accordion
                    preExpanded={[this.state.podcastSelectionIndex.toString()]} >
                    {this.createPodcasts()}
                </Accordion >
            </Card>
        </div>


    }




}



const mapDispatchToProps = (dispatch: Dispatch<PodcastsActionTypes | EpisodesActionTypes>) => {
    return bindActionCreators({
        fetchPodcasts,
        fetchMorePodcasts,
        updatePodcast,
        fetchEpisodes,
        fetchMoreEpisodes
    }, dispatch);
};


const mapStateToProps = (state: any) => {
    return {
        podcasts: state.podcasts,
        podcastsContent: state.podcasts.podcasts.content,
        updatedPodcast: state.updatePodcast,
        episodes: state.episodes,
        episodesContent: state.episodes.episodes.content
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Podcasts);