import { AgreementActionTypes, AgreementState, FETCH_AGREEMENT_FAIL, FETCH_AGREEMENT_START, FETCH_AGREEMENT_SUCCESS, RegisterActionTypes, RegisterState, REGISTER_FAIL, REGISTER_START, REGISTER_SUCCESS } from "./types";

const agreementInitialState : AgreementState = {
    agreement: null,
    loading: false,
    error:false,
    message:''
};
const registerInitialState : RegisterState = {
    success: false,
    loading: false,
    error:false,
    message:''
};


const agreementReducer = (
    state = agreementInitialState,
    action: AgreementActionTypes
  ): AgreementState =>{

    switch (action.type) {
        case FETCH_AGREEMENT_START:
            return {
                ...state,
                loading:true,
                error:false
            }
        case FETCH_AGREEMENT_SUCCESS:
            return {
                ...state,
                error:false,
                agreement:action.payload,
                loading:false
            }
        case FETCH_AGREEMENT_FAIL:
            return {
                ...state,
                agreement:null,
                error:true,
                message:'Sözleşme yükleme işlemi başarısız',
                loading:false
            }
        
        default:
        return state
    }
  }
  const registerReducer = (
    state = registerInitialState,
    action: RegisterActionTypes
  ): RegisterState =>{

    switch (action.type) {
        case REGISTER_START:
            return {
                ...state,
                loading:true
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                success:true,
                loading:false
            }
        case REGISTER_FAIL:
            return {
                ...state,
                error:true,
                message:'Kayıt olma işlemi başarısız',
                loading:false
            }
        
        default:
        return state
    }
  }
const registerReducers = {
    agreement:agreementReducer,
    register:registerReducer
};

export default registerReducers;