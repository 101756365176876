import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
import Button from '../../components/UI/Button/Button';
import Card from '../../components/UI/Card/Card';
import Input from '../../components/UI/Input/Input';
import { urlValidationInitialize, validateUrl } from '../../store/createPodcast/actions';
import { UrlValidationActionTypes } from '../../store/createPodcast/actionTypes';
import './CreatePodcast.scss';
type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & any;

class CreatePodcast extends React.Component<Props> {
    constructor(props: any) {
        super(props);

        this.handleChangeInput = _.debounce(this.handleChangeInput, 2000);
    }
    state = {
        rssUrl: ''
    }
    componentDidMount() {
        this.props.initializeValidation();
    }
    handleChangeInput = (inputValue: string) => {
        if (inputValue.toString().trim().length > 0) {
            this.props.validateUrl(inputValue);
        }
        this.setState({ rssUrl: inputValue });

    }
    inputClassName = () => {
        let result = this.props.validation;
        if (result.response && this.state.rssUrl !== '') {
            if (result.response.errorList.unique) {
                return 'input--success';
            } else {
                return 'input--already-taken';
            }
        }
        if (result.error && this.state.rssUrl !== '') {
            return 'input--error';
        }
        if (result.loading && this.state.rssUrl !== '') {
            return 'input--disabled';
        }
        if (!result.error && !result.response && !result.loading && this.state.rssUrl === '') {
            return '';
        }
    }
    notificationMessage = () => {
        let result = this.props.validation;

        if (result.response && this.state.rssUrl !== '') {
            if (result.response.errorList.unique) {
                return 'Devam et\'i tıklayarak onaylayın.';
            } else {
                return 'Bu podcastin size ait olduğunu \'Devam et\'e tıklayarak onaylayın.';
            }
        }
        if (result.error && this.state.rssUrl !== '') {
            return result.error.message;
        }
        if (result.loading && this.state.rssUrl !== '') {
            return '';
        }
        if (!result.error && !result.response && !result.loading && this.state.rssUrl === '') {
            return '';
        }
    }

    render() {

        return (
            <div id="create-podcast-page" className="layout">
                <Card>
                    <h1>Podcast Yükle</h1>
                    <p className="notice">Yüklemek istediğiniz podcastin rss linkini girerek podcastinizi yükleyebilirsiniz.</p>
                    <Input
                        className={'rss ' + this.inputClassName()}
                        disabled={this.props.validation.loading}
                        // label="RSS Link"
                        onChange={this.handleChangeInput}
                        value=""
                        placeholder="RSS Link"
                        icon="fa fa-rss icon"
                        iconHref={true}
                    />
                    <div className={`input-notifications ${this.inputClassName()}`}>
                        <span className="message">{this.notificationMessage()}</span>
                        {
                            this.props.validation.error && this.props.validation.error.missingInfo && this.props.validation.error.missingInfo.map(
                                (info: string, index: number) =>
                                    info.includes('anchoremail')
                                        ?
                                        <div key={index}>
                                            <span className="message">- RSS beslemenizdeki e-posta adresi Anchor'a aittir. Bu e-postayı nasıl güncelleyeceğiniz hakkında daha fazla bilgi almak üzere Anchor'un sayfasına gitmek için</span>
                                            <a className="message" href="https://help.anchor.fm/hc/en-us/articles/360028557892" target="_blank" rel="noopener noreferrer"> tıklayın</a>.</div>
                                        :
                                        <span className="message" key={index}>{info}</span>
                            )
                        }
                    </div>

                    <Button onClick={() => this.props.history.push('/podcast-verify')} text={'Devam Et'} disabled={!this.props.validation.response} />
                </Card>
            </div>

        );
    }
}
const mapDispatchToProps = (dispatch: Dispatch<UrlValidationActionTypes>) => {

    return bindActionCreators(
        {
            validateUrl,
            initializeValidation: () => dispatch(urlValidationInitialize())
        },
        dispatch);
};

const mapStateToProps = (state: any) => {
    return {
        validation: state.validation
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatePodcast);