import { updatePodcasts } from "../shared/utility";
import * as types from "./types";


const podcastsInitialState : types.PodcastsState = {
    podcasts: {
        content:[],
        number: 0,
        size: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        first: false,
        last: false,
        sort: null,
    },
    moreLoading:false,
    loading:false,
    message:'',
    error:false
};
const updatePodcastInitialState : types.UpdatePodcastState = {
    success:false,
    message:'',
    loading:false,
    error:false,
    updatedPodcastId:0
};

const fetchPodcastsReducer = (
    state = podcastsInitialState,
    action: types.PodcastsActionTypes
  ): types.PodcastsState =>{

    switch (action.type) {
        case types.FETCH_PODCASTS_START:
            return {
                ...state,
                loading:true
            }
        case types.FETCH_PODCASTS_SUCCESS:
            return  {
                ...state,
                podcasts: action.payload,
                loading: false
            }
        case types.FETCH_PODCASTS_FAIL:
            return { 
                ...state,
                loading: false,
                message:'Podcast yükleme işlemi başarısız.',
                error:action.error 
            } 
        case types.FETCH_PODCASTS_RESET:
            return {
                ...podcastsInitialState
            }  
        case types.UPDATE_PODCASTS_SUCCESS:
            return {
                ...state,
                podcasts:{
                    ...state.podcasts,
                    content:updatePodcasts(state.podcasts.content,action.podcastId, action.status)
                }
                
            }  
   
        case types.FETCH_MORE_PODCASTS_START:
            return {
                ...podcastsInitialState,
                podcasts:state.podcasts,
                moreLoading:true
            }
        
        case types.FETCH_MORE_PODCASTS_SUCCESS:
            return  {
                ...podcastsInitialState,
                podcasts: {
                    ...action.payload,
                    content:[...state.podcasts.content, ...action.payload.content]
                },
                moreLoading: false
            }

        case types.FETCH_MORE_PODCASTS_FAIL:
            return { 
                loading:false,
                podcasts:state.podcasts,
                message:'Podcaste ait episode kayıtları yükleme işlemi başarısız.',
                moreLoading: false,
                error:action.error 
            }
        case types.FETCH_MORE_PODCASTS_RESET:
            return {
                ...podcastsInitialState
            }         

        default:
        return state
    }
  }
  const updatePodcastReducer = (
    state = updatePodcastInitialState,
    action: types.UpdatePodcastActionTypes
  ): types.UpdatePodcastState =>{

    switch (action.type) {
        case types.UPDATE_PODCAST_START:
            return {
                ...state,
                loading:true,
                updatedPodcastId:action.updatedPodcastId

            }
        case types.UPDATE_PODCAST_SUCCESS:
            return  {
                    ...state,
                    success:true,
                    message:'Podcast güncelleme işlemi başarılı.',
                    loading: false
                }
        case types.UPDATE_PODCAST_FAIL:
            return { 
                ...state,
                loading: false,
                message:'Podcast güncelleme işlemi başarısız.',
                error:true
            }
        case types.UPDATE_PODCAST_RESET:
            return {
                ...updatePodcastInitialState
            }   
        default:
        return state
    }
  }

  const podcastsReducer = {
    podcasts:fetchPodcastsReducer,
    updatePodcast:updatePodcastReducer
};

export default podcastsReducer;