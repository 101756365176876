import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from '../Spinner/Spinner';
import './VirtualScroll.scss';
import Error from '../../Error/Error';
import Button from '../Button/Button';
const VirtualScroll = (props: any) => {

    return <InfiniteScroll
        dataLength={props.length}
        hasMore={props.hasMore}
        next={props.fetchMore}
        style={{ maxHeight: props.maxHeight }}
        loader={<Spinner />}

        endMessage={props.endMessage ?
            <Error className="center" message={'Podcast\'e ait tüm episode kayıtları listelenmiştir.'} />
            : null
        }
    >
        {props.children}
        {
            props.hasMore ?
                <div className="center mod-buttons">
                    <Button className="button moderation-btn load" text="Devamını Yükle.." width="150px" onClick={props.fetchMore} />
                    {/* <Button className="button moderation-btn load" text={props.totalElements + " Tamamını Yükle"} width="150px" onClick={props.fetchMore} /> */}
                </div>
                : null
        }

    </InfiniteScroll>

}
export default VirtualScroll;