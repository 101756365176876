import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import clickOutside from '../../../store/shared/hooks';
import './Menu.scss';

const Menu = (props: any) => {
  const ref: any = useRef();
  const [menuOpened, setMenuOpened] = useState(false);

  clickOutside(ref, () => {
    setMenuOpened(false)
  });

  return (
    <div ref={ref} className={`menu ${menuOpened ? 'menu--opened' : ''}`}>
      <div className="menu__selection" onClick={() => setMenuOpened(!menuOpened)}>
        <div className="menu__content">
          <div className="menu__username">
            {props.userName}
          </div>
          <i className="icon-arrow"></i>
        </div>
      </div>
      <div className="menu__list">
        <Link to="/yardim"> <span className="menu__item">
          Sık Sorulan Sorular
      </span>
        </Link>

        <Link to="/my-agreements">
          <span className="menu__item">
            Sözleşmelerim
      </span>
        </Link>
        <Link to="/logout"><span className="menu__item" >
          Çıkış Yap
      </span>
        </Link>
      </div>
    </div>
  );
}
export default Menu;