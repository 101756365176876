import { IEpisodeList } from "./interfaces";

export const FETCH_EPISODES_START = 'FETCH_EPISODES_START';
export const FETCH_EPISODES_SUCCESS = 'FETCH_EPISODES_SUCCESS';
export const FETCH_EPISODES_FAIL = 'FETCH_EPISODES_FAIL';
export const FETCH_EPISODES_RESET = 'FETCH_EPISODES_RESET';
export const FETCH_MORE_EPISODES_START = 'FETCH_MORE_EPISODES_START';
export const FETCH_MORE_EPISODES_SUCCESS = 'FETCH_MORE_EPISODES_SUCCESS';
export const FETCH_MORE_EPISODES_FAIL = 'FETCH_MORE_EPISODES_FAIL';

interface FetchEpisodesStartAction {
    type: typeof FETCH_EPISODES_START
}

interface FetchEpisodesSuccessAction {
    type: typeof FETCH_EPISODES_SUCCESS
    payload: IEpisodeList
}

interface FetchEpisodesFailAction {
    type: typeof FETCH_EPISODES_FAIL
    error: any
};

interface FetchEpisodesResetAction {
    type: typeof FETCH_EPISODES_RESET
}

interface FetchMoreEpisodesStartAction {
    type: typeof FETCH_MORE_EPISODES_START
}

interface FetchMoreEpisodesSuccessAction {
    type: typeof FETCH_MORE_EPISODES_SUCCESS
    payload: IEpisodeList
}

interface FetchMoreEpisodesFailAction {
    type: typeof FETCH_MORE_EPISODES_FAIL
    error: any
}

export interface EpisodesState {
    episodes: IEpisodeList,
    loading: boolean,
    moreLoading: boolean,
    error: any,
    message: string
}

export type EpisodesActionTypes = (
    FetchEpisodesStartAction |
    FetchEpisodesSuccessAction |
    FetchEpisodesFailAction |
    FetchEpisodesResetAction |
    FetchMoreEpisodesStartAction |
    FetchMoreEpisodesSuccessAction |
    FetchMoreEpisodesFailAction
);
