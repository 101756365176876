import { IEpisodeList } from "../moderationEpisode/interfaces";
import { IPodcastList } from "./interfaces";

export const FETCH_AIEXPLICITLOGS_START = 'FETCH_AIEXPLICITLOGS_START';
export const FETCH_AIEXPLICITLOGS_RESET = 'FETCH_AIEXPLICITLOGS_RESET';
export const FETCH_AIEXPLICITLOGS_SUCCESS = 'FETCH_AIEXPLICITLOGS_SUCCESS';
export const FETCH_AIEXPLICITLOGS_FAIL = 'FETCH_AIEXPLICITLOGS_FAIL';

export const FETCH_AIEPISODES_START = 'FETCH_AIEPISODES_START';
export const FETCH_AIEPISODES_RESET = 'FETCH_AIEPISODES_RESET';
export const FETCH_AIEPISODES_SUCCESS = 'FETCH_AIEPISODES_SUCCESS';
export const FETCH_AIEPISODES_FAIL = 'FETCH_AIEPISODES_FAIL';

export const FETCH_PODCASTS_START = 'FETCH_PODCASTS_START';
export const FETCH_PODCASTS_SUCCESS = 'FETCH_PODCASTS_SUCCESS';
export const FETCH_PODCASTS_FAIL = 'FETCH_PODCASTS_FAIL';
export const FETCH_PODCASTS_RESET = 'FETCH_PODCASTS_RESET';
export const UPDATE_PODCASTS_SUCCESS = 'UPDATE_PODCASTS_SUCCESS';

export const FETCH_CHANGELOGS_START = 'FETCH_CHANGELOGS_START';
export const FETCH_CHANGELOGS_RESET = 'FETCH_CHANGELOGS_RESET';
export const FETCH_CHANGELOGS_SUCCESS = 'FETCH_CHANGELOGS_SUCCESS';
export const FETCH_CHANGELOGS_FAIL = 'FETCH_CHANGELOGS_FAIL';

export const UPDATE_PODCAST_START = 'UPDATE_PODCAST_START';
export const UPDATE_PODCAST_SUCCESS = 'UPDATE_PODCAST_SUCCESS';
export const UPDATE_PODCAST_FAIL = 'UPDATE_PODCAST_FAIL';
export const UPDATE_PODCAST_RESET = 'UPDATE_PODCAST_RESET';

export const FETCH_MORE_PODCASTS_START = 'FETCH_MORE_PODCASTS_START';
export const FETCH_MORE_PODCASTS_SUCCESS = 'FETCH_MORE_PODCASTS_SUCCESS';
export const FETCH_MORE_PODCASTS_FAIL = 'FETCH_MORE_PODCASTS_FAIL';
export const FETCH_MORE_PODCASTS_RESET = 'FETCH_MORE_PODCASTS_RESET';

// ai episodes
interface FetchAiEpisodesStartAction {
    type: typeof FETCH_AIEPISODES_START
}

interface FetchAiEpisodesResetAction {
    type: typeof FETCH_AIEPISODES_RESET
}

interface FetchAiEpisodesSuccessAction {
    type: typeof FETCH_AIEPISODES_SUCCESS
    payload: any
}

interface FetchAiEpisodesFailAction {
    type: typeof FETCH_AIEPISODES_FAIL,
    error: any
};

//FETCH PODCAST
interface FetchPodcastsStartAction {
    type: typeof FETCH_PODCASTS_START
}

interface FetchPodcastsResetAction {
    type: typeof FETCH_PODCASTS_RESET
}

interface FetchPodcastsSuccessAction {
    type: typeof FETCH_PODCASTS_SUCCESS
    payload: IPodcastList
}

interface FetchPodcastsFailAction {
    type: typeof FETCH_PODCASTS_FAIL,
    error: any
};

interface UpdatePodcastsSuccessAction {
    type: typeof UPDATE_PODCASTS_SUCCESS,
    podcastId: any,
    status: any
};

//FETCH CHANGELOGS
interface fetchChangelogsStartAction {
    type: typeof FETCH_CHANGELOGS_START
}

interface fetchChangelogsResetAction {
    type: typeof FETCH_CHANGELOGS_RESET
}

interface fetchChangelogsSuccessAction {
    type: typeof FETCH_CHANGELOGS_SUCCESS,
    payload: Logs
}

interface fetchChangelogsFailAction {
    type: typeof FETCH_CHANGELOGS_FAIL,
    error: any
};

//FETCH AI EXPLICITLOGS
interface fetchAiExplicitLogsStartAction {
    type: typeof FETCH_AIEXPLICITLOGS_START
}

interface fetchAiExplicitLogsResetAction {
    type: typeof FETCH_AIEXPLICITLOGS_RESET
}

interface fetchAiExplicitLogsSuccessAction {
    type: typeof FETCH_AIEXPLICITLOGS_SUCCESS,
    payload: AiExplicitLogsInterface
}

interface fetchAiExplicitLogsFailAction {
    type: typeof FETCH_AIEXPLICITLOGS_FAIL,
    error: any
};

//FETCH UPDATE PODCAST
interface UpdatePodcastStartAction {
    type: typeof UPDATE_PODCAST_START,
    updatedPodcastId: number
}

interface UpdatePodcastResetAction {
    type: typeof UPDATE_PODCAST_RESET
}

interface UpdatePodcastSuccessAction {
    type: typeof UPDATE_PODCAST_SUCCESS
}

interface UpdatePodcastFailAction {
    type: typeof UPDATE_PODCAST_FAIL
}

//FETCH PODCASTS MORE
interface FetchMorePodcastsStartAction {
    type: typeof FETCH_MORE_PODCASTS_START
}

interface FetchMorePodcastsResetAction {
    type: typeof FETCH_MORE_PODCASTS_RESET
}

interface FetchMorePodcastsSuccessAction {
    type: typeof FETCH_MORE_PODCASTS_SUCCESS
    payload: IPodcastList
}
interface FetchMorePodcastsFailAction {
    type: typeof FETCH_MORE_PODCASTS_FAIL
    error: any
}

export interface PodcastsState {
    podcasts: IPodcastList,
    moreLoading: boolean,
    loading: boolean,
    error: any,
    message: string
}

export interface UpdatePodcastState {
    success: boolean,
    message: string | null,
    error: any,
    loading: boolean,
    updatedPodcastId: number
}

export interface ChangelogsState {
    logs: Logs | undefined,
    loading: boolean
}

export interface AiExplicitLogsState {
    logs: AiExplicitLogsInterface | undefined,
    loading: boolean
}

export interface AiExplicitLogsInterface {
    word: string,
    start_time: string,
    end_time: string
}

export interface Logs {
    approveStatus: number,
    message?: string | null,
    createdAt: string,
}

export type PodcastsActionTypes = (
    FetchPodcastsStartAction |
    FetchPodcastsSuccessAction |
    FetchPodcastsFailAction |
    FetchPodcastsResetAction |
    FetchMorePodcastsStartAction |
    FetchMorePodcastsSuccessAction |
    FetchMorePodcastsFailAction |
    FetchMorePodcastsResetAction |
    UpdatePodcastsSuccessAction
);

export type UpdatePodcastActionTypes = (
    UpdatePodcastFailAction |
    UpdatePodcastStartAction |
    UpdatePodcastSuccessAction |
    UpdatePodcastResetAction
);

export type ChangelogsActionTypes = (
    fetchChangelogsStartAction |
    fetchChangelogsResetAction |
    fetchChangelogsSuccessAction |
    fetchChangelogsFailAction
);

export interface AiEpisodesState {
    episodes: IEpisodeList,
    loading: boolean,
    moreLoading: boolean,
    error: any,
    message: string
}

export type AiEpisodesActionTypes = (
    FetchAiEpisodesStartAction |
    FetchAiEpisodesResetAction |
    FetchAiEpisodesSuccessAction |
    FetchAiEpisodesFailAction
);

export type AiExplicitLogsActionTypes = (
    fetchAiExplicitLogsStartAction |
    fetchAiExplicitLogsResetAction |
    fetchAiExplicitLogsSuccessAction |
    fetchAiExplicitLogsFailAction
);