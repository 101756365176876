import React from 'react';
import { IEpisode } from '../../store/episodes/interfaces';
import { dateFormat } from '../../store/shared/utility';
import Tooltip from '../UI/Tooltip/Tooltip';
import './Episode.scss';
const Episode = (props: { episode: IEpisode }) => {
  const checkImageSource = (url: any) => {
    const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;
    const DATA_URL_PATTERN = /^data:(?:image(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video(?:mpeg|mp4|ogg|webm)|audio(?:mp3|oga|ogg|opus));base64,[a-z0-9+]+=*$/i;

    if (url === "null" || url === null || url === '' || url.length === 0 || url === "about:blank") {
      return null;
    };
    if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) {
      const image = React.createElement("img", { src: url, alt: '' });
      return image;
    };

    return null;
  }

  const calculateListenDuration = (duration: any = 0) => {
    duration = duration >= 0 ? duration : 0;
    if (duration > 0) {
      return Math.round(duration / 60) + 'dk';
    } else {
      return 0;
    }
  }

  return (

    <div className="episode">
      <div className="episode--content">
        <div className="episode--info img-box">
          {checkImageSource(props.episode.imageUrl)}
        </div>

        <div className="info-container">

          <div className="episode--info epi-title title-border col-sm-7 col-md-7 col-lg-5">
            {/* <span className="episode--title">Başlık</span> */}
            <span className="episode--text">{props.episode.title}</span>
          </div>
          <div className="episode--info  col-sm-5 col-md-5 col-lg-3">
            {/* <span className="episode--title">Yayın Tarihi</span> */}
            <span className="episode--text epi-date">{dateFormat(props.episode.publishDate || '')}</span>
          </div>

          <div className="episode--info  col-sm-7 col-md-7 col-lg-2">
            <span className="episode--title">Dinleme <Tooltip width="11px" text="Bir kullanıcı, podcastindeki herhangi bir bölümü dinlemeye başladığı 0. saniye ölçülür." /></span>
            <span className="episode--text">{props.episode.listenCount}</span>
          </div>

          <div className="episode--info  col-sm-5 col-md-5 col-lg-2">
            <span className="episode--title">Süre </span>
            <span className="episode--text">{calculateListenDuration(props.episode.totalListenDuration)}</span>
          </div>
        </div>
      </div>


    </div>

  );
}
export default Episode;

