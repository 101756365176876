
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';

interface LoginStartAction {
    type: typeof LOGIN_START
}
interface LoginSuccessAction {
    type:typeof LOGIN_SUCCESS,
    token:any,
    userInfo: any,
    userRole: any
}
interface LoginFailAction {
    type:typeof LOGIN_FAIL,
    error:any
}
interface LogoutAction {
    type:typeof LOGOUT
}
interface LoginRedirectAction {
    type:typeof LOGIN_REDIRECT,
    path:string,
    token:any
}
export interface LoginState {
    token: any,
    userInfo:any
    loading: boolean,
    error:any,
    redirectPath:string,
    agreementApproved: boolean,
    userRole: any,
}
export type LoginActionTypes = (
    LoginStartAction | 
    LoginSuccessAction | 
    LoginFailAction | 
    LogoutAction |
    LoginRedirectAction
);
