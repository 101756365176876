import React from 'react';
import { dateFormat } from '../../../store/shared/utility';
import Button from '../../UI/Button/Button';
import '../Episode.scss';
import './EpiModeration.scss';
const EpiModeration = (props: any) => {
  const checkImageSource = (url: any) => {
    const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;
    const DATA_URL_PATTERN = /^data:(?:image(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video(?:mpeg|mp4|ogg|webm)|audio(?:mp3|oga|ogg|opus));base64,[a-z0-9+]+=*$/i;

    if (url === "null" || url === null || url === '' || url.length === 0 || url === "about:blank") {
      return null;
    };
    if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) {
      const image = React.createElement("img", { src: url, alt: '' });
      return image;
    };

    return null;
  }

  const modalAction = (value: number, podcast: any, modal: boolean, from?: string) => {
    props.modalAction(value, podcast, modal, from ? from : 'episode')
  }

  return (

    <div className="episode">
      <div className="episode--content main-container">

        <div className="episode--info img-box">
          {checkImageSource(props.episode.imageUrl)}
        </div>

        <div className="episode--content inner-container">
          <div className="episode--info title-container">
            <span className="episode--title fixed">{props.episode.title}</span>
            <span className="episode--text date">{dateFormat(props.episode.publishDate || '')}</span>

          </div>
          <div className="episode--content detail-container">
            <div className={"episode--info title player " +
              (props.filter === '&status=ACTIVE' ? 'divide' : 'merge') +
              (props.filter === 'aiepisodes' ? ' divide-60' : '')
            }>
              <span className="episode--text" >
                <audio controls src={props.episode.contentUrl} preload="none"></audio>
              </span>
            </div>
            {
              props.filter === 'aiepisodes' ?
                <div className="episode--info btn-container">
                  <div className="podcast mod-buttons">
                    <Button
                      text={'Kelimeler'}
                      className="moderation-button info"
                      onClick={() => modalAction(3, props.episode, true, 'aiepisodes')}>
                    </Button>
                  </div>
                </div>
                : null
            }
            {
              props.filter === '&status=ACTIVE' || props.filter === 'aiepisodes' ?
                <div className="episode--info btn-container">
                  <div className="podcast mod-buttons">
                    <Button
                      text={props.episode.approveStatus === 1 ? 'Aktif' : 'Aktif Yap'}
                      disabled={props.episode.approveStatus === 1}
                      className={props.episode.approveStatus === 1 ? 'moderation-button confirm confirmed' : 'moderation-button confirm'}
                      onClick={() => modalAction(1, props.episode, true, 'episode')}>
                    </Button>
                    <Button
                      text={props.episode.approveStatus === 2 ? 'Pasif' : 'Pasif Yap'}
                      disabled={props.episode.approveStatus === 2}
                      className={props.episode.approveStatus === 2 ? 'moderation-button reject rejected' : 'moderation-button reject'}
                      onClick={() => modalAction(2, props.episode, true, 'episode')}>
                    </Button>
                  </div>
                </div>
                : null
            }
          </div>
        </div>

      </div>
    </div>

  );
}
export default EpiModeration;

