import { EmailChangeVerificationActionTypes, EmailChangeVerificationState, EmailMatchVerificationActionTypes, EmailMatchVerificationState, EMAIL_CHANGE_VERIFICATION_FAIL, EMAIL_CHANGE_VERIFICATION_INITIALIZE, EMAIL_CHANGE_VERIFICATION_START, EMAIL_CHANGE_VERIFICATION_SUCCESS, EMAIL_MATCH_VERIFICATION_FAIL, EMAIL_MATCH_VERIFICATION_START, EMAIL_MATCH_VERIFICATION_SUCCESS, GetCategoriesStartActionTypes, GetCategoriesState, GET_CATEGORIES_FAIL, GET_CATEGORIES_INITIALIZE, GET_CATEGORIES_START, GET_CATEGORIES_SUCCESS, UrlValidationActionTypes, UrlValidationState, URL_VALIDATION_FAIL, URL_VALIDATION_INITIALIZE, URL_VALIDATION_START, URL_VALIDATION_SUCCESS } from './actionTypes';




const urlValidationInitialState : any = {
    rssUrl:null,
    response:null,
    loading:false,
    error:null
};

const getCategoriesInitialState: any = {
    response: null,
    loading: false,
    error: null
};

const emailMatchVerificationInitialState : EmailMatchVerificationState = {
  response:null,
   error:null,
   loading:false
};

const emailChangeVerificationInitialState : EmailChangeVerificationState = {
    response:null,
     error:null,
     loading:false
  };

  const urlValidationReducer = (
    state = urlValidationInitialState,
    action: UrlValidationActionTypes
  ): UrlValidationState =>{
    switch (action.type) {
        
        case URL_VALIDATION_START:
            return {
                error:null,
                response:null,
                rssUrl:null,
                loading:true
            }
            case URL_VALIDATION_INITIALIZE:
                return {
                    error:null,
                    response:null,
                    loading:false,
                    rssUrl:null
                }
        case URL_VALIDATION_SUCCESS:
            return  {
                    error:null,
                    response: action.payload,
                    loading: false ,
                rssUrl:action.rssUrl               }
             
        case URL_VALIDATION_FAIL:
            return { 
                    response:null,
                    loading: false,
                    error:action.error ,
                    rssUrl:null
                }
                 
        default:
        return state
    }
  }


const getCategoriesReducer = (
    state = getCategoriesInitialState,
    action: GetCategoriesStartActionTypes
): GetCategoriesState => {
    switch (action.type) {

        case GET_CATEGORIES_START:
            return {
                error: null,
                response: null,
                loading: true
            }
        case GET_CATEGORIES_INITIALIZE:
            return {
                error: null,
                response: null,
                loading: false,
            }
        case GET_CATEGORIES_SUCCESS:
            return {
                error: null,
                response: action.payload,
                loading: false
            }

        case GET_CATEGORIES_FAIL:
            return {
                response: null,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

  const emailMatchVerificationReducer = (
    state = emailMatchVerificationInitialState,
    action: EmailMatchVerificationActionTypes
  ): EmailMatchVerificationState =>{
    switch (action.type) {
        
        case EMAIL_MATCH_VERIFICATION_START:
            return {
                error:null,
                response:null,
                loading:true
            }
            case EMAIL_MATCH_VERIFICATION_SUCCESS:
                return {
                    error:null,
                    response:action.payload,
                   loading:false,
                }
        case EMAIL_MATCH_VERIFICATION_FAIL:
            return  {
                    loading:false,
                    response:null,
                    error:action.error
            }
                 
        default:
        return state
    }
  }

  const emailChangeVerificationReducer = (
    state = emailChangeVerificationInitialState,
    action: EmailChangeVerificationActionTypes
  ): EmailChangeVerificationState =>{
    switch (action.type) {
        
        case EMAIL_CHANGE_VERIFICATION_START:
            return {
                error:null,
                response:null,
               loading:true,
            }
            case EMAIL_CHANGE_VERIFICATION_INITIALIZE:
                return {
                    error:null,
                    response:null,
                   loading:false
                }
            case EMAIL_CHANGE_VERIFICATION_SUCCESS:
                return {
                    error:null,
                    response:action.payload,
                   loading:false,
                }
        case EMAIL_CHANGE_VERIFICATION_FAIL:
            return  {
                    loading:false,
                    response:null,
                    error:action.error
            }
               
        default:
        return  state;
    }
  }

  const podcastReducer = {
    validation:urlValidationReducer,
    emailMatch:emailMatchVerificationReducer,
    emailChange:emailChangeVerificationReducer,
    categories:getCategoriesReducer,
};

export default podcastReducer;
