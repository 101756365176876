import { GetCategoriesResponse, UrlValidationResponse } from "./types";

export const URL_VALIDATION_START = 'URL_VALIDATION_START';
export const URL_VALIDATION_SUCCESS = 'URL_VALIDATION_SUCCESS';
export const URL_VALIDATION_FAIL = 'URL_VALIDATION_FAIL';
export const URL_VALIDATION_INITIALIZE = 'URL_VALIDATION_INITIALIZE';

export const GET_CATEGORIES_START = 'GET_CATEGORIES_START';
export const GET_CATEGORIES_INITIALIZE = 'GET_CATEGORIES_INITIALIZE';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const EMAIL_MATCH_VERIFICATION_START = 'EMAIL_MATCH_VERIFICATION_START';
export const EMAIL_MATCH_VERIFICATION_SUCCESS = 'EMAIL_MATCH_VERIFICATION_SUCCESS';
export const EMAIL_MATCH_VERIFICATION_FAIL = 'EMAIL_MATCH_VERIFICATION_FAIL';

export const EMAIL_CHANGE_VERIFICATION_START = 'EMAIL_CHANGE_VERIFICATION_START';
export const EMAIL_CHANGE_VERIFICATION_SUCCESS = 'EMAIL_CHANGE_VERIFICATION_SUCCESS';
export const EMAIL_CHANGE_VERIFICATION_FAIL = 'EMAIL_CHANGE_VERIFICATION_FAIL';
export const EMAIL_CHANGE_VERIFICATION_INITIALIZE = 'EMAIL_CHANGE_VERIFICATION_INITIALIZE';



interface UrlValidationStart {
    type: typeof URL_VALIDATION_START
}
interface UrlValidationInitialize {
    type: typeof URL_VALIDATION_INITIALIZE
}
interface UrlValidationSuccess {
    type: typeof URL_VALIDATION_SUCCESS
    payload:UrlValidationResponse,
    rssUrl:string | null
}

interface UrlValidationFail {
    type: typeof URL_VALIDATION_FAIL
    error:any
};

interface GetCategoriesStart {
    type: typeof GET_CATEGORIES_START
}
interface GetCategoriesInitialize {
    type: typeof GET_CATEGORIES_INITIALIZE
}
interface GetCategoriesSuccess {
    type: typeof GET_CATEGORIES_SUCCESS
    payload: GetCategoriesResponse,
}

interface GetCategoriesFail {
    type: typeof GET_CATEGORIES_FAIL
    error: any
};

interface EmailMatchVerificationStartAction {
    type: typeof EMAIL_MATCH_VERIFICATION_START
}
  
interface EmailMatchVerificationSuccessAction {
    type: typeof EMAIL_MATCH_VERIFICATION_SUCCESS
    payload:any
}

interface EmailMatchVerificationFailAction {
    type: typeof EMAIL_MATCH_VERIFICATION_FAIL,
    error:any 
};

interface EmailChangeVerificationStartAction {
    type: typeof EMAIL_CHANGE_VERIFICATION_START
}
interface EmailChangeVerificationInitializeAction {
    type: typeof EMAIL_CHANGE_VERIFICATION_INITIALIZE
}
  
interface EmailChangeVerificationSuccessAction {
    type: typeof EMAIL_CHANGE_VERIFICATION_SUCCESS
    payload:any
}

interface EmailChangeVerificationFailAction {
    type: typeof EMAIL_CHANGE_VERIFICATION_FAIL,
    error:any 
};
export interface EmailMatchVerificationState {
    response:any,
    error:any,
    loading:boolean
}
export interface EmailChangeVerificationState {
    response:any,
    error:any,
    loading:boolean
}


export interface UrlValidationState {
    rssUrl:string | null,
    response: any,
    error:any,
    loading:boolean
}

export interface GetCategoriesState {
    response: any,
    error: any,
    loading: boolean
}

export type EmailMatchVerificationActionTypes = EmailMatchVerificationStartAction | EmailMatchVerificationSuccessAction | EmailMatchVerificationFailAction; 
export type EmailChangeVerificationActionTypes = EmailChangeVerificationStartAction | EmailChangeVerificationSuccessAction | EmailChangeVerificationFailAction | EmailChangeVerificationInitializeAction; 

export type UrlValidationActionTypes = UrlValidationStart | UrlValidationSuccess | UrlValidationFail | UrlValidationInitialize; 

export type GetCategoriesStartActionTypes = GetCategoriesStart | GetCategoriesInitialize | GetCategoriesSuccess | GetCategoriesFail; 
