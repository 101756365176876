import * as types from './types';
const episodesInitialState : types.EpisodesState = {
    episodes: {
        content:[],
        number: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        first: false,
        last: false,
        sort: null
    },
    moreLoading:false,
    loading:false,
    error:false,
    message:''
};

const fetchEpisodesReducer = (
    state = episodesInitialState,
    action: types.EpisodesActionTypes
  ): types.EpisodesState =>{

    switch (action.type) {
        case types.FETCH_EPISODES_START:
            return {
                ...state,
                loading:true
            }
      
        case types.FETCH_EPISODES_SUCCESS:

            return  {
                    ...state,
                    episodes: action.payload,
                    loading: false
            }
              
        case types.FETCH_EPISODES_FAIL:
            return { 
                    ...state,
                    loading: false,
                    message: 'Podcaste ait episode kayıtlarını yükleme işlemi başarısız.',
                    error:action.error 
        }

        case types.FETCH_EPISODES_RESET:
            return {
                ...episodesInitialState
            }  
        
        case types.FETCH_MORE_EPISODES_START:
            return {
                ...state,
                moreLoading:true
            }
    case types.FETCH_MORE_EPISODES_SUCCESS:

        return  {
                ...state,
                episodes: {
                    ...action.payload,
                    content:[...state.episodes.content,...action.payload.content]
                },
                moreLoading: false
        }
    case types.FETCH_MORE_EPISODES_FAIL:

        return  {
                ...state,
                error:action.error,
                moreLoading: false
        }
   
            
        
        default:
        return state
    }
  }

  const episodesReducer = {
    episodes:fetchEpisodesReducer
};

export default episodesReducer;