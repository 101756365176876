import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Agreement from '../../../components/Agreement/Agreement';
import Button from '../../../components/UI/Button/Button';
import Card from '../../../components/UI/Card/Card';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import Input from '../../../components/UI/Input/Input';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { fetchAgreement, register } from '../../../store/auth/register/actions';
import { AgreementActionTypes, RegisterActionTypes } from '../../../store/auth/register/types';
import './Register.scss';

type Props = ReturnType<typeof mapStateToProps> &
ReturnType<typeof mapDispatchToProps> ;

 class Register extends React.Component <Props> {
  
  state = {
    firstName:'',
    lastName:'',
    checked:false
  }
  
  componentDidMount() {
      this.props.fetchAgreement();
  }
  componentDidUpdate(prevProps:any) {
    if(prevProps.registerStore !== this.props.registerStore) {
      if(this.props.registerStore.success) {
        window.location.href= '/my-podcasts';
      }
    }
  }

  checkFormValidation = ()  =>{
    return this.state.firstName.trim().length > 0 && this.state.lastName.trim().length > 0 && this.state.checked && this.props.agreement && this.props.agreement.agreement ;
  }
  checkboxChange = () => {
    const checkedStatus = !this.state.checked;
    this.setState({checked:checkedStatus});
  }
  handleSubmit = () => {
    if(this.checkFormValidation()) {
      this.props.register(this.state.firstName,this.state.lastName,this.props.agreement.agreement.uuid);
    }
  }
  renderRegister () {
   
      return (
      <div className={this.props.registerLoading ? 'overlay' : ''}>
      <h1>Kayıt Ol</h1>
      <p className="notice">Yayın yükleyebilmek için öncelikle kayıt olmanız gerekmektedir.</p> 
      <Input disabled={false}  label="Adınız" onChange={(inputValue:any) => this.setState({firstName:inputValue})} value={this.state.firstName}/>       
      <Input disabled={false}  label="Soyadınız" onChange={(inputValue:any) => this.setState({lastName:inputValue})} value={this.state.lastName} />
      <h3 className="contract-box--title">Sözleşmeyi Onayla</h3>
      <Agreement loading={this.props.agreement.loading} agreement={this.props.agreement.agreement} error= {this.props.agreement.message}/>
      <Checkbox className={!this.props.agreement.agreement ? 'checkbox--disabled' :''  } label="Okudum, onaylıyorum." disabled={!this.props.agreement.agreement} checked={this.state.checked} onChange={() => this.checkboxChange()}/>
      <Button text="Tamam" disabled= {!this.checkFormValidation()} className={this.checkFormValidation() ? '' : 'button--secondary'} onClick={() => this.handleSubmit()}/>
      </div>
      )
    
  }
    render() {
   
        return <div id="register-page" className="layout login">
          <Card >
          {this.props.registerLoading ? <Spinner className="overlay" /> : null}
          {this.renderRegister()}
         </Card>
        </div>
       
      
    }

}

const mapDispatchToProps = (dispatch: Dispatch<RegisterActionTypes | AgreementActionTypes>) =>{
   
  return bindActionCreators(
      {fetchAgreement,register} ,
     dispatch);

};


const mapStateToProps = (state:any) => {

return {
   isAuthenticated: state.loginStore.token  && state.loginStore.agreementApproved,
   registerStore:state.register,
   agreement:state.agreement,
   loginStore:state.loginStore,
   registerLoading: state.register.loading
};
};
export default connect(mapStateToProps,mapDispatchToProps) (Register);