import React from 'react';
import {
    Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel
} from "react-accessible-accordion";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
import EpiModeration from '../../components/Episode/EpiModeration/EpiModeration';
import Error from '../../components/Error/Error';
import PodModeration from '../../components/Podcast/PodModeration/PodModeration';
import Button from '../../components/UI/Button/Button';
import Card from '../../components/UI/Card/Card';
import Dropdown from '../../components/UI/Dropdown/Dropdown';
import Input from '../../components/UI/Input/Input';
import Modal from '../../components/UI/Modal/Modal';
import Spinner from '../../components/UI/Spinner/Spinner';
import TextArea from '../../components/UI/TextArea/TextArea';
import VirtualScroll from '../../components/UI/VirtualScroll/VirtualScroll';
import { fetchEpisodes, fetchMoreEpisodes, resetFetchEpisodes } from '../../store/moderationEpisode/actions';
import { IEpisode } from '../../store/moderationEpisode/interfaces';
import {
    fetchMorePodcasts, fetchPodcasts, updatePodcast,
    fetchPodcastsWithParam, fetchChangelogs, resetUpdatedPodcast, resetfetchChangelogs, fetchAiEpisodes, fetchAiExplicitLogs
} from '../../store/moderations/actions';
import { IPodcast } from '../../store/moderations/interfaces';
import { dateFormat } from '../../store/shared/utility';
import './Moderation.scss';

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & any;

class Moderation extends React.Component<Props> {
    sortOptions = [
        { key: 1, param: 'title', value: 'Başlık' },
        { key: 2, param: 'id', value: 'Eklenme Tarihi' }
    ];

    state = {
        podcastPaginationParams: {
            page: 1,
            size: 10,
            query: ''
        },
        episodePaginationParams: {
            page: 1,
            size: 10,
            query: ''
        },
        podcastSelectionIndex: 0,
        initialSelection: true,
        podcastSelectionId: 0,
        modalPodcast: {
            id: 0,
            imageUrl: '',
            category: '',
            author: '',
            title: '',
            lastUpdate: '',
        },
        modalEpisode: {
            audioDuration: "",
            channelId: 0,
            contentLength: "",
            contentType: "",
            contentUrl: "",
            description: "",
            guid: "",
            id: 0,
            imageUrl: "",
            listenCount: 0,
            listenFizyCount: 0,
            publishDate: "",
            title: "",
            url: null,
        },
        modalFrom: '',
        value: 0,
        isModal: false,
        isAiInfoModal: false,
        modalMessage: '',

        logs: [],
        updatedPodcast: {
            success: undefined,
            message: null,
            loading: undefined,
            error: undefined,
            updatedPodcastId: undefined
        },
        selectedFilter: '&status=PASSIVE&safe=true',
        search: '',
        timeoutObj: setTimeout(() => {

        }, 1000)
        ,
        sortObj: { key: 2, param: 'id', value: 'Eklenme Tarihi' },
    }

    // selectedFilter = '&status=PASSIVE&safe=true';
    // Filtre butonlarından son basılan filtre statüsünü dinamik olarak tutmak için
    // Filtre değiştikçe bu değişken de değişir, statüsü değiştirilen podcast işlemi-
    // sonrasında filtreye göre listenin güncel halini servisten çekmek için bu değişken kullanılıyor

    componentDidMount() {
        this.props.fetchPodcasts();
    }

    componentDidUpdate(prevProps: any) {
        if (this.state.selectedFilter !== 'aiepisodes') {
            if (this.props.podcastsContent?.length > 0 && this.state.initialSelection) {
                this.props.fetchEpisodes(this.props.podcastsContent[0].id);
                this.setState({ initialSelection: false, podcastSelectionId: this.props.podcastsContent[0].id });
            }
        }
    }

    togglePodcast = (selectedIndex: number, podcastId: number) => {
        if (!this.state.isModal) {
            const selection = this.state.podcastSelectionIndex === selectedIndex ? -1 : selectedIndex;
            this.setState({
                podcastSelectionIndex: selection
            });
            if (selection > -1) {
                this.setState({
                    episodePaginationParams: {
                        page: 1,
                        size: 10,
                        query: this.state.episodePaginationParams.query
                    }
                })
                this.setState({ podcastSelectionId: podcastId })
                this.props.fetchEpisodes(podcastId);
            }
        }
    }

    updatePodcastStatus = (podcastId: number, updatedStatus: boolean, message: string, from?: string | undefined) => {

        this.props.updatePodcast(podcastId, updatedStatus, message, from)
        setTimeout(() => {
            if (this.props.updatedPodcast.success) {
                this.handleModalClose();
                this.resetUpdatedPodcast()
                this.resetfetchChangelogs()
                if (this.state.selectedFilter === 'aiepisodes') {
                    this.fetchAiEpisodes();
                } else if (from === 'podcast') {
                    this.props.fetchPodcastsWithParam(this.state.selectedFilter + '&query=' + this.state.search)
                } else if (from === 'episode') {
                    this.props.fetchEpisodes(this.state.podcastSelectionId);
                }
            } else {
                // this.resetUpdatedPodcast();
            }
        }, 2000);
    }

    isModalOpen = () => {
        return this.state.isModal &&
            !this.props.updatedPodcast.loading &&
            this.props.updatedPodcast.success ? true : false;
    }

    resetUpdatedPodcast = () => {
        this.props.resetUpdatedPodcast()
    }

    resetFetchEpisodes = () => {
        this.props.resetFetchEpisodes()
    }

    resetfetchChangelogs = () => {
        this.props.resetfetchChangelogs()
    }

    fetchPodcastsWithParam = (param?: string) => {
        const currentPage = this.state.podcastPaginationParams;
        currentPage.page = 1; // tab yeni değiştiği için page default değere çekiliyor.
        param ? this.setState({ selectedFilter: param }) : param = this.state.selectedFilter; // seçili kategori butonunun param değerini dinamik tutar
        this.togglePodcast(this.state.podcastSelectionIndex, 0) // tab değiştirirken toggle kapatmak için
        this.props.fetchPodcastsWithParam(param + '&query=' + this.state.search + '&sort=' + this.state.sortObj.param) // ilgili tab ile podcastlerin çekilmesi için
        this.resetFetchEpisodes() // önceden çekilmiş episode başka podcast altında gözükmesin diye
    }

    fetchPodcastsWithSearch = (query: string, filter: string) => {
        const currentPage = this.state.podcastPaginationParams;
        currentPage.page = 1; // tab yeni değiştiği için page default değere çekiliyor.
        this.togglePodcast(this.state.podcastSelectionIndex, 0) // tab değiştirirken toggle kapatmak için
        this.resetFetchEpisodes() // önceden çekilmiş episode başka podcast altında gözükmesin diye
        setTimeout(() => {
            if (this.state.selectedFilter === 'aiepisodes') {
                this.fetchAiEpisodes();
            } else {
                this.props.fetchPodcastsWithParam(filter + '&query=' + query + '&sort=' + this.state.sortObj.param) // ilgili tab ile podcastlerin çekilmesi için
            }
        }, 1000);
    }

    // fetch AI Episodes (with status Passive)
    fetchAiEpisodes = (param?: string) => {
        this.props.fetchAiEpisodes( { page: 1, size: 10, query: this.state.search, sort: this.state.sortObj.param});
    }

    fetchMoreAiEpisodes = () => {
        const currentPage = this.state.episodePaginationParams;
        currentPage.page = (currentPage.page + 1);
        this.setState({ episodePaginationParams: currentPage });
        this.props.fetchAiEpisodes(currentPage);
    }

    // fetch first podcast's episodes
    fetchEpisodeDynamically = () => {
        if (this.props.podcastsContent?.length > 0 && this.state.initialSelection) {
            this.props.fetchEpisodes(this.props.podcastsContent[0].id);
            this.setState({ initialSelection: false, podcastSelectionId: this.props.podcastsContent[0].id });
        }
    }

    hasMorePodcasts = () => {
        if (this.props.podcastsContent?.length > 0) {
            return this.props.podcastsContent.length >= this.props.podcasts?.podcasts?.totalElements ? false : true;
        } else {
            return false;
        }
    }

    hasMoreAiEpisode = () => {
        if (this.props.podcastsContent?.length > 0) {
            return this.props.podcastsContent.length >= this.props.podcasts?.podcasts?.totalElements ? false : true;
        } else {
            return false;
        }
    }

    fetchMorePodcasts = () => {
        const currentPage = this.state.podcastPaginationParams;
        currentPage.page = (currentPage.page + 1);
        this.setState({ podcastPagination: currentPage });
        this.props.fetchMorePodcasts(currentPage, this.state.selectedFilter + '&query=' + this.state.search + '&sort=' + this.state.sortObj.param);
    }

    fetchMoreEpisodes = () => {
        const currentPage = this.state.episodePaginationParams;
        currentPage.page = (currentPage.page + 1);
        this.setState({ episodePaginationParams: currentPage });
        this.props.fetchMoreEpisodes(this.state.podcastSelectionId, currentPage);
    }

    podcastOverlay = (updatePodcastId: number) => {
        return this.props.updatedPodcast.loading && this.props.updatedPodcast.updatedPodcastId === updatePodcastId;
    }

    createEpisodes = () => {
        // console.log('createEpisodes')

        if (this.props.episodes.loading) {
            return <Spinner />
        } else if (this.props.episodes.error) {
            return <Error className="center" message={this.props.episodes.message} />
        } else {
            if (this.props.episodesContent?.length === 0) {
                return <Error className="center" message={'Podcast\'e ait episode kaydı bulunamadı.'} />
            } else {
                return <AccordionItemPanel className="episodes" onClick={(event) => event.stopPropagation()}>
                    <VirtualScroll maxHeight="400px" endMessage={true}
                        hasMore={this.props.episodesContent?.length >= this.props.episodes.episodes?.totalElements ? false : true}
                        length={this.props.episodesContent?.length} fetchMore={this.fetchMoreEpisodes}
                        totalElements={this.props.episodes?.episodes?.totalElements - this.props.episodes.episodes?.content?.length} >
                        {this.props.episodesContent.map((episode: IEpisode, index: number) =>
                            <EpiModeration
                                key={index}
                                episode={episode}
                                modalAction={this.modalAction}
                                filter={this.state.selectedFilter}
                            />
                        )}
                    </VirtualScroll>
                </AccordionItemPanel>;
            }
        }
    }

    createAiEpisodes = () => {

        if (this.props.aiEpisodes.loading) {
            return <Spinner />
        } else {
            if (this.props.aiEpisodes?.error) {
                return <Error message={this.props.aiEpisodes?.message} />
            } else {
                return <div>
                    <VirtualScroll
                        maxHeight="100%"
                        hasMore={this.props.aiEpisodes?.episodes?.totalPages > this.state.episodePaginationParams.page ? true : false}
                        length={this.props.aiEpisodes?.episodes?.content?.length ? this.props.aiEpisodes?.episodes?.content?.length : 0}
                        fetchMore={this.fetchMoreAiEpisodes}
                        totalElements={this.props.aiEpisodes?.episodes?.totalElements - this.props.aiEpisodes?.episodes?.content?.length}
                        endMessage={false}
                    >
                        {this.props.aiEpisodes?.episodes?.content.map((episode: IEpisode, index: number) =>

                            <div className="ai-episodes ai-episode-container">
                                <div className="episodes">
                                    <EpiModeration
                                        key={index}
                                        episode={episode}
                                        modalAction={this.modalAction}
                                        filter={this.state.selectedFilter}
                                    />
                                </div>
                            </div>
                        )}
                        
                    </VirtualScroll>

                </div>
            }
        }

    }

    sortSelectionChange = (value: any) => {
        // console.log(value)
        this.setState({ sortObj: value })
        // console.log(this.state.sortObj)
        setTimeout(() => {
            if (this.state.selectedFilter === 'aiepisodes') {
                this.fetchAiEpisodes();

            } else {
                this.fetchPodcastsWithParam()
            }
        }, 300);

    }

    createPodcasts = () => {
        if (this.props.podcasts.loading) {
            return <Spinner />
        } else {
            if (this.props.podcasts.error) {
                return <Error message={this.props.podcasts.message} />
            } else {
                return <div>
                    <VirtualScroll
                        maxHeight="100%"
                        endMessage={false}
                        hasMore={this.hasMorePodcasts()}
                        length={this.props.podcastsContent?.length}
                        fetchMore={this.fetchMorePodcasts}
                        totalElements={this.props.podcasts.podcasts?.totalElements}
                    >
                        {this.props.podcastsContent && this.props.podcastsContent.map(
                            (podcast: IPodcast, index: number) =>

                                <AccordionItem
                                    key={index}
                                    uuid={index.toString()}
                                    onClick={() => this.togglePodcast(index, podcast.id)}
                                    className={`podcasts ${this.podcastOverlay(podcast.id) ? 'overlay' : ''}`}
                                >

                                    {this.podcastOverlay(podcast.id) ? <Spinner className="overlay" /> : null}

                                    <AccordionItemHeading >
                                        <AccordionItemButton className="podcast--button">
                                            <PodModeration
                                                className={this.state.podcastSelectionIndex === index ? 'active' : ''}
                                                podcast={podcast} selectionChange={this.updatePodcastStatus}
                                                modalAction={this.modalAction}
                                            />
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    {this.state.podcastSelectionIndex === index ? this.createEpisodes() : null}
                                </AccordionItem>
                        )}
                    </VirtualScroll>

                </div>
            }
        }
    }

    checkImageSource = (url?: any) => {
        const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

        /** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
        const DATA_URL_PATTERN = /^data:(?:image(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video(?:mpeg|mp4|ogg|webm)|audio(?:mp3|oga|ogg|opus));base64,[a-z0-9+]+=*$/i;

        if (url === "null" || url === null || url === '' || url.length === 0 || url === "about:blank") {
            return null;
        };
        if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) {
            const image = React.createElement("img", { src: url, alt: '' });
            return image;
        };

        return null;
    }

    handleInfoModalClose = async () => {
        this.setState({ isAiInfoModal: false })
    }

    handleModalClose = async () => {
        this.setState({
            modalPodcast: {
                id: 0,
                imageUrl: '',
                category: '',
                author: '',
                title: '',
                lastUpdate: '',
            }
        });
        this.setState({
            modalEpisode: {
                audioDuration: '',
                channelId: 0,
                contentLength: '',
                contentType: '',
                contentUrl: '',
                description: '',
                guid: '',
                id: 0,
                imageUrl: '',
                listenCount: 0,
                listenFizyCount: 0,
                publishDate: '',
                title: '',
                url: null,

            }
        });
        this.setState({ value: 0 })
        this.setState({ isModal: false })
        this.setState({ modalMessage: '' })
        this.setState({
            updatedPodcast: {
                success: undefined,
                message: undefined,
                loading: undefined,
                error: undefined,
                updatedPodcastId: undefined
            }
        });
        this.resetUpdatedPodcast();
        this.resetfetchChangelogs();
    }

    modalAction = (value: number, content: any, modal: boolean, from?: string) => {
        if (value && content && modal) {
            // console.log('modal', modal)
            this.setState({ modalFrom: from })

            this.setState({ value: value })
            if (from === 'podcast') {
                this.setState({ isAiInfoModal: false })
                this.setState({ isModal: modal })
                this.setState({ modalPodcast: content })
                this.fetchChangelogs(content.id, 'podcast');
            } else if (from === 'episode') {
                this.setState({ isAiInfoModal: false })
                this.setState({ isModal: modal })
                this.setState({ modalEpisode: content });
                this.fetchChangelogs(content.id, 'episode');
            } else if (from === 'aiepisodes') {
                this.setState({ isAiInfoModal: modal }) // ai info modal opening
                this.setState({ modalEpisode: content }); // modal içeriği aktarılır
                this.fetchAiExplicitLogs(content.id, 'aiepisodes'); // info modal için explicit words listesini çeker
            }
        }
    }

    fetchAiExplicitLogs = (id: number, from?: string | undefined) => {
        this.props.fetchAiExplicitLogs(id, from)
    }

    fetchChangelogs = (id: number, from?: string | undefined) => {
        this.props.fetchChangelogs(id, from)
    }

    handleChangeInput = (e: any) => {
        this.setState({ modalMessage: e })
    }

    createModal = () => {
        let type = this.state.modalFrom;
        // console.log('type:', type)
        let initials: any = {};
        let content: any;


        if (type === 'episode') {
            initials = {
                title: 'Episode Moderasyon',
                yes: 'Aktif Yap',
                no: 'Pasif Yap',
                date: this.state.modalEpisode.publishDate
            }
            content = this.state.modalEpisode;

        } else if (type === 'podcast') {
            initials = {
                title: 'Podcast Moderasyon',
                yes: 'Onayla',
                no: 'Reddet',
                date: this.state.modalPodcast?.lastUpdate
            }
            content = this.state.modalPodcast;
        } else if (type === 'aiepisodes') {
            initials = {
                title: 'Explicit Episode Moderasyon',
                yes: 'Aktif Yap',
                no: 'Pasif Yap',
                date: this.state.modalEpisode?.publishDate
            }
            content = this.state.modalEpisode;
        }
        return (
            <div className="podcasts">

                <Modal clickedOutside={true} title="" className={`modal big`}
                    bodyClass={'moder-modal'}
                    sencondClass={'wide'}
                    show={this.isModalOpen} onClose={() => this.handleModalClose()}
                >
                    <h2>{initials.title}</h2>

                    <div className="mo-podcast">
                        <div className={`podcast--content`} >
                            <div className="podcast--info img-box">
                                {content?.imageUrl ? this.checkImageSource(content?.imageUrl) : ''}
                            </div>

                            {type === 'podcast' ?
                                <div className="podcast--info w20">
                                    <span className="podcast--title">Kategori</span>
                                    <span className="podcast--text">{this.state.modalPodcast?.category}</span>
                                </div> : null}
                            {type === 'podcast' ?
                                <div className="podcast--info w20">
                                    <span className="podcast--title">Yayıncı</span>
                                    <span className="podcast--text">{this.state.modalPodcast?.author}</span>
                                </div>

                                : null
                            }
                            {
                                (
                                    (type === 'podcast' && content?.title.length < 90)
                                    || type === 'episode'
                                    || type === 'aiepisodes'
                                ) ?
                                    <div className="podcast--info w50">
                                        <span className="podcast--title">Başlık</span>
                                        <span className="podcast--text">{content?.title}</span>
                                    </div>
                                    : null
                            }
                            <div className="podcast--info w20">
                                <span className="podcast--title">Yüklenme Tarihi</span>
                                <span className="podcast--text">{dateFormat(initials.date || '')}</span>
                            </div>

                            <div className="podcast mod-buttons">
                                <Button
                                    text={this.state.value === 1 ? initials.yes : initials.no}
                                    disabled={this.state.value === 1}
                                    className={this.state.value === 1 ? 'moderation-button confirm confirmed' : 'moderation-button reject rejected'}
                                    onClick={() => null}>
                                </Button>
                            </div>
                        </div>
                        {
                            (type === 'podcast' && content?.title.length >= 90)
                                ?
                                <div className={`podcast--content`} >
                                    <div className="podcast--info img-box padding-zero">
                                        <span className="podcast--title"></span>
                                        <span className="podcast--text"></span>
                                    </div>
                                    <div className="podcast--info w80 padding-zero">
                                        <span className="podcast--title">Başlık</span>
                                        <span className="podcast--text">{content?.title}</span>
                                    </div>
                                </div> : null
                        }
                    </div>

                    <TextArea
                        className="moderation"
                        onChange={this.handleChangeInput}
                        rows={3}
                        label={"Lütfen bu alana '" + (this.state.value === 1 ? initials.yes : initials.no) + "' işleminiz için detaylı bilgi giriniz."}
                        disabled={false}
                        max={1000} //maxLength gönderilmezse default karakter sayısı limiti geçerli
                        showMax={true} // textarea altında limit ve mevcut karakter sayısı bilgisi göstermek için
                    >
                    </TextArea>
                    <div className="moderation-btn-container">
                        <Button text="Vazgeç" className="moderation-btn cancel"
                            onClick={() => this.handleModalClose()}
                        />
                        <Button text="Kaydet"
                            disabled={this.props.updatedPodcast?.loading ? true : ((this.state.modalMessage === '') || (this.props.updatedPodcast?.updatedPodcastId && !this.props.updatedPodcast?.error) ? true : false)}
                            className="moderation-btn save"
                            onClick={() => this.updatePodcastStatus(
                                content.id,
                                this.state.value === 1 ? true : false,
                                this.state.modalMessage,
                                type
                            )}
                        />
                        {
                            this.props.updatedPodcast?.message && this.props.updatedPodcast?.updatedPodcastId === content?.id ?
                                <div className={`text-right message ${this.props.updatedPodcast?.error ? 'error' :
                                    (this.props.updatedPodcast?.loading && !this.props.updatedPodcast?.error) ? 'normal' : 'success'}`}
                                >
                                    {this.props.updatedPodcast?.message}
                                </div> : null
                        }
                    </div>

                    <div className="changelog-container">
                        {
                            this.props.changelogs?.length > 0 ?
                                <h3 className="title">Önceki Kayıtlar:</h3> :
                                this.props.changelogs && !this.props.changelogs.loading ?
                                    <div>Önceden bir işlem yapılmamıştır.</div>
                                    : null
                        }

                        <div className="changelogs">
                            {
                                this.props.changelogs?.length > 0 ?
                                    <div className="changeline">
                                        <div className="podcast--content" >
                                            <div className="podcast--info w20">
                                                <span className="podcast--title">Değişiklik Tarihi:</span>
                                            </div>
                                            <div className="podcast--info w20">
                                                <span className="podcast--title">Durum:</span>
                                            </div>
                                            <div className="podcast--info w50">
                                                <span className="podcast--title">Açıklama:</span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                            }
                            {/* changelog : */}
                            {
                                this.props.changelogs && this.props.changelogs.map(
                                    (log: any, index: number) =>
                                        <div key={`chlog-${index}`} className="changeline">
                                            <div className="podcast--content" >
                                                <div className="podcast--info w20">
                                                    {/* Değişiklik Tarihi: */}
                                                    <span className="podcast--text">
                                                        {dateFormat(log?.createdAt || '')}
                                                    </span>
                                                </div>

                                                <div className="podcast--info w20">
                                                    {/* Durum: */}
                                                    <span className="podcast--text">
                                                        {log?.approveStatus ? "Onaylandı" : "Reddedildi"}
                                                    </span>
                                                </div>

                                                <div className="podcast--info w50">
                                                    {/* Açıklama: */}
                                                    <span className="podcast--text">
                                                        {log?.message ? log?.message : ''}
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                )
                            }
                        </div>
                    </div>
                </Modal >
            </div>
        )
    }

    calculateListenDuration = (duration: any = 0) => {
        duration = duration >= 0 ? duration : 0;
        if (duration > 0) {
            // 86400 -> 1 gün
            return (duration < 86400) ? // bir günden az ise
                new Date(0, 0, 0, 0, 0, duration).toTimeString().slice(0, 8) :
                // bir günden daha uzun ise generik olarak başına gün ekleniyor --> 1:23:59:59 gibi
                Math.round(duration / 86400) + ':' + new Date(0, 0, 0, 0, 0, duration).toTimeString().slice(0, 8)
        } else {
            return 0;
        }
    }

    createExplicitInfoModal = () => {
        let type = this.state.modalFrom;
        // console.log('type:', type)
        let initials: any = {};
        let content: any;

        if (type === 'aiepisodes') {
            initials = {
                title: 'Explicit Episode Bilgisi',
                date: this.state.modalEpisode?.publishDate
            }
            content = this.state.modalEpisode;
        }
        return (
            <div className="podcasts">

                <Modal clickedOutside={false} title="" className={`modal big`}
                    bodyClass={'moder-modal'}
                    sencondClass={'wide'}
                    show={this.isModalOpen} onClose={() => this.handleModalClose()}
                >
                    <h2>{initials.title}</h2>

                    <div className="mo-podcast">
                        <div className={`podcast--content`} >
                            <div className="podcast--info img-box">
                                {content?.imageUrl ? this.checkImageSource(content?.imageUrl) : ''}
                            </div>

                            {type === 'podcast' ?
                                <div className="podcast--info w20">
                                    <span className="podcast--title">Kategori</span>
                                    <span className="podcast--text">{this.state.modalPodcast?.category}</span>
                                </div> : null}
                            {type === 'podcast' ?
                                <div className="podcast--info w20">
                                    <span className="podcast--title">Yayıncı</span>
                                    <span className="podcast--text">{this.state.modalPodcast?.author}</span>
                                </div>

                                : null
                            }
                            {
                                (
                                    (type === 'podcast' && content?.title.length < 90)
                                    || type === 'episode'
                                    || type === 'aiepisodes'
                                ) ?
                                    <div className="podcast--info w50">
                                        <span className="podcast--title">Başlık</span>
                                        <span className="podcast--text">{content?.title}</span>
                                    </div>
                                    : null
                            }
                            <div className="podcast--info w20">
                                <span className="podcast--title">Yüklenme Tarihi</span>
                                <span className="podcast--text">{dateFormat(initials.date || '')}</span>
                            </div>

                            {
                                this.state.selectedFilter === '&status=ACTIVE' || this.state.selectedFilter === 'aiepisodes' ?
                                    <div className="episode--info btn-container">
                                        <div className="podcast mod-buttons">
                                            <Button
                                                text={initials.approveStatus === 1 ? 'Aktif' : 'Aktif Yap'}
                                                disabled={initials.approveStatus === 1}
                                                className={initials.approveStatus === 1 ? 'moderation-button confirm confirmed' : 'moderation-button confirm'}
                                                onClick={() => {
                                                    this.handleInfoModalClose();
                                                    setTimeout(() => {
                                                        this.modalAction(1, content, true, 'episode')
                                                    }, 100);

                                                }}>
                                            </Button>
                                            <Button
                                                text={initials.approveStatus === 2 ? 'Pasif' : 'Pasif Yap'}
                                                disabled={initials.approveStatus === 2}
                                                className={initials.approveStatus === 2 ? 'moderation-button reject rejected' : 'moderation-button reject'}
                                                onClick={() => {
                                                    this.handleInfoModalClose();
                                                    setTimeout(() => {
                                                        this.modalAction(2, content, true, 'episode')
                                                    }, 100);
                                                }}>
                                            </Button>
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                        {
                            (type === 'podcast' && content?.title.length >= 90)
                                ?
                                <div className={`podcast--content`} >
                                    <div className="podcast--info img-box padding-zero">
                                        <span className="podcast--title"></span>
                                        <span className="podcast--text"></span>
                                    </div>
                                    <div className="podcast--info w80 padding-zero">
                                        <span className="podcast--title">Başlık</span>
                                        <span className="podcast--text">{content?.title}</span>
                                    </div>
                                </div> : null
                        }
                    </div>

                    <div className="moderation-btn-container">
                        <Button text="Kapat" className="moderation-btn cancel"
                            onClick={() => this.handleInfoModalClose()}
                        />
                    </div>

                    <div className="changelog-container">
                        {
                            this.props.aiExplicitLogs?.logs?.length > 0 ?
                                <h3 className="title">Kısıtlı Kelimeler Listesi:</h3>
                                :
                                this.props.aiExplicitLogs && !this.props.aiExplicitLogs.loading
                                    ?
                                    <div>İlgili herhangi bir kayıt bulunamadı</div>
                                    : null
                        }

                        <div className="changelogs">
                            {
                                this.props.aiExplicitLogs?.logs?.length > 0 ?
                                    <div className="changeline">
                                        <div className="podcast--content" >
                                            <div className="podcast--info w20">
                                                <span className="podcast--title">Kelime:</span>
                                            </div>
                                            <div className="podcast--info w20">
                                                <span className="podcast--title">Öncelik:</span>
                                            </div>
                                            <div className="podcast--info w20">
                                                <span className="podcast--title">Başlangıç:</span>
                                            </div>
                                            <div className="podcast--info w30">
                                                <span className="podcast--title">Bitiş:</span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                            }
                            {/* changelog : */}
                            {
                                this.props.aiExplicitLogs?.logs && this.props.aiExplicitLogs.logs.map(
                                    (log: any, index: number) =>
                                        <div key={`chlog-${index}`} className="changeline">
                                            <div className="podcast--content" >
                                                <div className="podcast--info w20">
                                                    {/* Kelime: */}
                                                    <span className="podcast--text">
                                                        {log?.word}
                                                    </span>
                                                </div>
                                                <div className="podcast--info w20">
                                                    {/* Priority: */}
                                                    <span className="podcast--text">
                                                        {log?.priority}
                                                    </span>
                                                </div>

                                                <div className="podcast--info w20">
                                                    {/* Başlangıç süresi: */}
                                                    <span className="podcast--text">
                                                        {this.calculateListenDuration(log?.start_time)}
                                                    </span>
                                                </div>

                                                <div className="podcast--info w30">
                                                    {/* Bitiş süresi: */}
                                                    <span className="podcast--text">
                                                        {this.calculateListenDuration(log?.end_time)}
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                )
                            }
                        </div>
                    </div>
                </Modal >
            </div>
        )
    }

    searchQuerySetter = (query: string) => {

        clearTimeout(this.state.timeoutObj) // önceki timeout siliniyor

        this.setState({
            timeoutObj:
                setTimeout(() => {
                    this.setState({ search: query })
                    this.fetchPodcastsWithSearch(encodeURIComponent(query), this.state.selectedFilter)
                }, 800)
        })
    }

    sortPodcastList = (param: string) => {
        this.setState({ sort: param });

    }

    render() {
        return <div className="layout" id="podcasts-page">
            <Card>
                <div className="title-area">
                    <h1>Podcast Moderasyon</h1>
                </div>
                <Input
                    placeholder="Buradan arama yapabilirsiniz"
                    className={'search'}
                    icon="fa fa-search icon"
                    iconHref={true}
                    disabled={false}
                    onChange={(query: any) => this.searchQuerySetter(query)}
                ></Input>

                <div className="filter-container">
                    <Button
                        text="Onay Bekleyenler" disabled={this.props.podcasts.loading || this.props.aiEpisodes.loading}
                        onClick={() => { this.fetchPodcastsWithParam('&status=PASSIVE&safe=true'); }}
                        className={this.state.selectedFilter === '&status=PASSIVE&safe=true' ? "filter-button active" : "filter-button"}>
                    </Button>

                    <Button
                        text="Onaylananlar" disabled={this.props.podcasts.loading || this.props.aiEpisodes.loading}
                        onClick={() => { this.fetchPodcastsWithParam('&status=ACTIVE'); }}
                        className={this.state.selectedFilter === '&status=ACTIVE' ? "filter-button active" : "filter-button"}>
                    </Button>

                    <Button
                        text="Reddedilenler" disabled={this.props.podcasts.loading || this.props.aiEpisodes.loading}
                        onClick={() => { this.fetchPodcastsWithParam('&status=REJECT'); }}
                        className={this.state.selectedFilter === '&status=REJECT' ? "filter-button active" : "filter-button"}>
                    </Button>

                    <Button
                        text="Filtrelenenler" disabled={this.props.podcasts.loading || this.props.aiEpisodes.loading}
                        onClick={() => { this.fetchPodcastsWithParam('&status=PASSIVE&safe=false'); }}
                        className={this.state.selectedFilter === '&status=PASSIVE&safe=false' ? "filter-button active" : "filter-button"}>
                    </Button>

                    <Button
                        text="AI Episodelar" disabled={this.props.podcasts.loading || this.props.aiEpisodes.loading}
                        onClick={() => {
                            this.fetchAiEpisodes();
                            this.setState({ selectedFilter: 'aiepisodes' })
                            this.setState({ episodePaginationParams: { page: 1, size: 10, query: '' } })
                        }}
                        className={this.state.selectedFilter === 'aiepisodes' ? "filter-button active" : "filter-button"}>
                    </Button>

                    <Dropdown
                        className={'sort-filter'}
                        selectionChange={(selection: any) => this.sortSelectionChange(selection)}
                        options={this.sortOptions}
                        value={this.state.sortObj.key}
                        detailDisplay={"Sırala"}
                        disabled={this.props.podcasts.loading}
                        selectedOption={this.state.sortObj} />
                </div>
                <Accordion preExpanded={this.state.selectedFilter !== 'aiepisodes' ?
                    [this.state.podcastSelectionIndex?.toString()] : []
                } >
                    {
                        this.state.selectedFilter === 'aiepisodes' ?
                            this.createAiEpisodes() :
                            this.createPodcasts()
                    }
                </Accordion >
            </Card>
            {
                this.state.isModal ?
                    <div className="podcast">
                        {
                            this.createModal()
                        }
                    </div>
                    : null
            }
            {
                this.state.isAiInfoModal ?
                    <div className="podcast">
                        {
                            this.state.selectedFilter === 'aiepisodes' ?
                                this.createExplicitInfoModal() :
                                null
                        }
                    </div>
                    : null
            }
        </div>
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        fetchPodcasts,
        fetchMorePodcasts,
        updatePodcast,
        fetchEpisodes,
        fetchMoreEpisodes,
        fetchPodcastsWithParam,
        fetchChangelogs,
        resetUpdatedPodcast,
        resetfetchChangelogs,
        resetFetchEpisodes,
        fetchAiEpisodes,
        fetchAiExplicitLogs
    }, dispatch);
};

// data çekilirken
const mapStateToProps = (state: any) => {
    // console.log('aiepisodes', state?.aiEpisodes)
    // console.log('aiExplicitLogs', state?.aiExplicitLogs)
    return {
        podcasts: state.podcasts,
        podcastsContent: state.podcasts?.podcasts?.content,
        updatedPodcast: state.updatePodcast,
        episodes: state.episodes,
        episodesContent: state.episodes?.episodes?.content ? state.episodes?.episodes?.content : [],
        changelogs: state.changelogs.logs,
        aiEpisodes: state.aiEpisodes,
        aiExplicitLogs: state.aiExplicitLogs.logs ? state.aiExplicitLogs : [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Moderation);