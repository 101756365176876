import React from 'react';
import './NoPodcast.scss';
import Button from '../../components/UI/Button/Button';
import Card from '../../components/UI/Card/Card';
import img from '../../assets/images/icons/no-podcast.svg';
import { Link } from 'react-router-dom';

class NoPodcast extends  React.Component {
render () {
    return(
        <div id="no-podcast-page" className="layout">
        <Card>
         <div className="text-center">
                <img alt="" src={img}/>
                <h2>Henüz yüklü podcastiniz bulunmamaktadır. Platformlarda paylaştığınız RSS linkini paylaşarak podcastlerinizi yükleyebilirsiniz.</h2>
                <Link to="/podcast-create"><Button text="Podcast Ekle"/></Link>
            </div>
        </Card>
        </div>
        
    );
}
   
}
export default NoPodcast;