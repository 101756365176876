import React, { useEffect, useState } from 'react';
import './Input.scss';

const Input = (props: any) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [value, setValue] = useState('');
  const [className, setClassName] = useState('');

  useEffect(() => {
    if (props.className) {
      setClassName(props.className);
    } else {
      setClassName('');
    }
  }, [props.className]);

  useEffect(() => {
    if (props.value && props.value.toString().trim().length > 0) {
      setValue(props.value);
      setInputFocused(true);
    } else {
      setInputFocused(false);
    }
  }, [props.value]);

  const focusInput = () => {
    setInputFocused(true);
  }

  const blurInput = (e: any) => {
    if (!e.target.value.length) {
      setInputFocused(false);
    }
  }
  const changeInput = (inputValue: string) => {
    if (props.onChange) {
      props.onChange(inputValue);
    }
    setValue(inputValue);
  }

  const clearValue = () => {
    changeInput('')
  }

  return <div className={`input ${inputFocused ? 'input--filled' : ''} ${className} `}>
    <label>
      <div className="input__area">
        {props.label && !props.placeholder ? <span className="input__label">{props.label}</span> : null}
        <div className={props.icon ? "input-icons" : ''}>
          {
            props.icon ?
              <a href="" onClick={() => props.iconHref && value ? clearValue() : null}
                className={props.icon && props.iconHref && value ? "icon-href" : "none-link"} >
                <i className={props.iconHref && value ? "fa fa-times icon" : props.icon}></i>
              </a>
              : null
          }
          <input
            type="text"
            className={props.icon ? "input-field" : ""}
            placeholder={props.placeholder ? props.placeholder : ''}
            disabled={props.disabled}
            onFocus={() => { focusInput() }}
            onBlur={(e) => { blurInput(e) }}
            onChange={(e) => {
              changeInput(e.target.value)
            }}
            value={value}
          />
        </div>
      </div>
    </label>

  </div>
}
export default Input;