import axios, { AxiosResponse } from 'axios';
import { getToken } from '../auth/login/actions';


axios.interceptors.request.use(req => {
    const token = getToken();
    if(token) {
        req.headers.Authorization = `${token}`
    }
    return req;
});

axios.interceptors.response.use((res:AxiosResponse) => {
  return res;  
}, error => {
  if(error.response.status === 401 || error.response.status === 403) {
    window.location.href= '/logout';
  } else {
    return error;
  }
});

export default axios;
