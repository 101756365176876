import React, { Fragment, useEffect, useRef, useState } from 'react';
import clickOutside from '../../../store/shared/hooks';
import './Dropdown.scss';
const Dropdown = (props: any) => {
  const ref: any = useRef();
  const [selectionVal, setSelectionVal] = useState({ key: 0, value: 'Pasif' });
  const [selectOpened, setSelectOpened] = useState(false);
  useEffect(() => {
    if (props.selectedOption) {
      setSelectionVal(props.selectedOption);
    }
  }, [props.selectedOption])
  const toggleSelectBox = (event: any) => {
    event.stopPropagation();
    if (!props.disabled) {
      setSelectOpened(!selectOpened);
    }
  }
  clickOutside(ref, () => {
    setSelectOpened(false)
  });
  const selectionChange = (event: any, selectedVal: any) => {
    event.stopPropagation();
    if (!props.disabled) {
      props.selectionChange(selectedVal)
      setSelectOpened(!selectOpened);
    }

  }
  return (
    <div ref={ref} className={`${props.className ? props.className : ''} select select--small ${selectOpened ? 'select--opened' : ''} select--selected  ${props.disabled ? 'select--disabled' : ''}`}>
      <div className="select__head" onClick={(event) => toggleSelectBox(event)}>
        <div className="select__selections">
          {
            // seçili olanı parantez içinde göstermek içindir // dropdown genişliği özgündür
            props.detailDisplay ?
            <Fragment > {props.detailDisplay} ({selectionVal.value})</Fragment>
            :
            <Fragment > {selectionVal.value}</Fragment>
          }

        </div>
        <i className="icon icon-arrow"></i>
      </div>
      <div className="select__options">
        {props.options && props.options.map((option: any, index: number) =>
          <div
            key={index}
            className={`select__option ${selectionVal.key === option.key ? 'select__option--selected' : ''}`}
            onClick={(event) => {
              selectionChange(event, option)
            }}
          >{option.value}</div>
        )}
      </div>
    </div>
  )
}
export default Dropdown;