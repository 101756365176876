import React, { useEffect, useState } from 'react';
import './TextArea.scss'
const TextArea = (props: any) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const maxLength = props.max ? props.max : 255;

  useEffect(() => {
    if (props.value) {
      setInputFocused(true);
    } else {
      setInputFocused(false)
    }
  }, [props.value]);

  function setInput(v: string) {
    setInputValue(v);
  }

  function focusInput() {
    setInputFocused(true);
  }

  return (
    <div className={
      `input ${props.className ? props.className : ''}
            ${inputFocused ? 'input--filled' : ''}
            ${props.error ? 'input--error' : ''}
            ${props.tag ? 'input--tag' : ''}
            ${props.disabled ? 'input--disabled' : ''}

            `
    }><label>
        <div className="input__area">
          <span className="input__label">{props.label}</span>
          <textarea rows={props.rows ? props.row : 7}
            disabled={props.disabled}
            value={props.value}
            maxLength={maxLength}
            onFocus={() => {
              focusInput()
            }}
            onChange={(e) => {
              props.onChange(e.target.value)
              setInput(e.target.value)
            }}
          />
        </div>
      </label>
      { props.showMax ?
        <span>
          <p className={"input-length " + (inputValue.length >= maxLength ? 'full' : '')}>
            {inputValue.length} / {maxLength}
          </p>
        </span> : null
      }
      <span className="input__error-message">
        {props.errorMessage}
      </span>
    </div>
  )
}
export default TextArea;