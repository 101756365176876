import React from 'react';
import './Checkbox.scss';
const Checkbox = (props:any) => {
    return <label className={`checkbox ${props.className ? props.className : ''}`}>
      <input checked={props.checked} type="checkbox" disabled={props.disabled}  onChange={(e) => {
        props.onChange(e);
      }}/>
      <span className="checkbox__label">{props.label}</span>
    </label>
  }

export default Checkbox;