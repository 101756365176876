import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PrivacyAgreement from '../../../components/PrivacyAgreement/PrivacyAgreement';
import Card from '../../../components/UI/Card/Card';
import { AgreementActionTypes } from '../../../store/auth/register/types';

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & any;
class PrivacyContainer extends React.Component<Props> {
    componentDidMount() {
    }

    render() {
        return <div id="my-agreements-page" className="layout">
            <Card>
                <h2>Gizlilik Sözleşmesi</h2>
                <PrivacyAgreement />
            </Card>
        </div>
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AgreementActionTypes>) => {
    return bindActionCreators(
        {},
        dispatch);
};

const mapStateToProps = (state: any) => {
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyContainer);