import { Dispatch } from "redux";
import axios from "../shared/axios.interceptor";
import { IPaginationRequest } from "../shared/type";
import { IPodcastList } from "./interfaces";
import * as types from "./types";

export const fetchPodcastsStart = (): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_PODCASTS_START
    };
}

export const fetchPodcastsReset = (): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_PODCASTS_RESET
    };
}

export const fetchPodcastsSuccess = (response: IPodcastList): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_PODCASTS_SUCCESS,
        payload: response
    };
}

export const fetchPodcastsFail = (error: any): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_PODCASTS_FAIL,
        error: error
    };
}
// ai episodes
export const fetchAiEpisodesStart = (): types.AiEpisodesActionTypes => {
    return {
        type: types.FETCH_AIEPISODES_START
    };
}

export const fetchAiEpisodesReset = (): types.AiEpisodesActionTypes => {
    return {
        type: types.FETCH_AIEPISODES_RESET
    };
}

export const fetchAiEpisodesSuccess = (response: IPodcastList): types.AiEpisodesActionTypes => {
    return {
        type: types.FETCH_AIEPISODES_SUCCESS,
        payload: response
    };
}

export const fetchAiEpisodesFail = (error: any): types.AiEpisodesActionTypes => {
    return {
        type: types.FETCH_AIEPISODES_FAIL,
        error: error
    };
} //end of ai episode

// clog
export const fetchChangelogsStart = (): types.ChangelogsActionTypes => {
    return {
        type: types.FETCH_CHANGELOGS_START
    };
}

export const fetchChangelogsReset = (): types.ChangelogsActionTypes => {
    return {
        type: types.FETCH_CHANGELOGS_RESET
    };
}

export const fetchChangelogsSuccess = (response: types.Logs): types.ChangelogsActionTypes => {
    return {
        type: types.FETCH_CHANGELOGS_SUCCESS,
        payload: response
    };
}

export const fetchChangelogsFail = (error: any): types.ChangelogsActionTypes => {
    return {
        type: types.FETCH_CHANGELOGS_FAIL,
        error: error
    };
} // end clog

// explicit log
export const fetchAiExplicitLogsStart = (): types.AiExplicitLogsActionTypes => {
    return {
        type: types.FETCH_AIEXPLICITLOGS_START
    };
}

export const fetchAiExplicitLogsReset = (): types.AiExplicitLogsActionTypes => {
    return {
        type: types.FETCH_AIEXPLICITLOGS_RESET
    };
}

export const fetchAiExplicitLogsSuccess = (response: types.AiExplicitLogsInterface): types.AiExplicitLogsActionTypes => {
    return {
        type: types.FETCH_AIEXPLICITLOGS_SUCCESS,
        payload: response
    };
}

export const fetchAiExplicitLogsFail = (error: any): types.AiExplicitLogsActionTypes => {
    return {
        type: types.FETCH_AIEXPLICITLOGS_FAIL,
        error: error
    };
}
// end explicit log

export const updatePodcastStart = (podcastId: number): types.UpdatePodcastActionTypes => {
    return {
        type: types.UPDATE_PODCAST_START,
        updatedPodcastId: podcastId
    };
}

export const updatePodcastReset = (): types.UpdatePodcastActionTypes => {
    return {
        type: types.UPDATE_PODCAST_RESET
    };
}

export const updatePodcastSuccess = (): types.UpdatePodcastActionTypes => {
    return {
        type: types.UPDATE_PODCAST_SUCCESS
    };
}

export const updatePodcastsSuccess = (podcastId: number, status: number): types.PodcastsActionTypes => {
    return {
        type: types.UPDATE_PODCASTS_SUCCESS,
        podcastId: podcastId,
        status: status
    };
}

export const updatePodcastFail = (): types.UpdatePodcastActionTypes => {
    return {
        type: types.UPDATE_PODCAST_FAIL
    };
}

export const fetchMorePodcastsStart = (): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_MORE_PODCASTS_START
    };
}

export const fetchMorePodcastsReset = (): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_MORE_PODCASTS_RESET
    };
}

export const fetchMorePodcastsSuccess = (podcasts: IPodcastList): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_MORE_PODCASTS_SUCCESS,
        payload: podcasts
    };
}

export const fetchMorePodcastsFail = (error: any): types.PodcastsActionTypes => {
    return {
        type: types.FETCH_MORE_PODCASTS_FAIL,
        error: error
    };
}

export function fetchMorePodcasts(params: IPaginationRequest, filter?: string) {

    // console.log('fetchMorePodcasts ', params)

    let reqParam = filter ?
        "?page=" + params.page +
        "&size=" + params.size +
        filter
        :
        "?page=" + params.page +
        "&size=" + params.size +
        "&status=";

    return (dispatch: Dispatch) => {
        dispatch(fetchMorePodcastsStart());
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/podcasts/moderation${reqParam}`, {})
            .then((res: any) => {
                dispatch(fetchMorePodcastsSuccess(res.data));
            }).catch(err => {
                dispatch(fetchMorePodcastsFail(err));
            });
    };
};

export function fetchPodcasts() {
    let reqParam = '?page=1&size=10&status=PASSIVE&safe=true&sort=id';


    return (dispatch: Dispatch) => {
        dispatch(fetchPodcastsReset());
        dispatch(fetchPodcastsStart());

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/podcasts/moderation${reqParam}`)
            .then((res: any) => {
                if (res?.data) {
                    dispatch(fetchPodcastsSuccess(res.data));
                }
                else {
                    dispatch(fetchPodcastsFail(true));

                }
            }).catch(error => {
                dispatch(fetchPodcastsFail(error));

            });
    };
};

export function fetchChangelogs(id: number, from: string | undefined) {
    const apiUrl = from === 'episode' ? '/episodes' : '';

    return (dispatch: Dispatch) => {
        dispatch(fetchChangelogsReset());
        dispatch(fetchChangelogsStart());

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/podcasts/${id}${apiUrl}/changelog`)
            .then((res: any) => {
                if (res.data) {
                    dispatch(fetchChangelogsSuccess(res.data));
                }
                else {
                    dispatch(fetchChangelogsFail(true));
                }
            }).catch(error => {
                dispatch(fetchChangelogsFail(error));
            });
    };
};

export function fetchAiExplicitLogs(id: number, from?: string) {

    return (dispatch: Dispatch) => {
        dispatch(fetchAiExplicitLogsReset());
        dispatch(fetchAiExplicitLogsStart());

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/podcasts/episodeAiResponses/${id}`)
            .then((res: any) => {
                if (res.data) {
                    dispatch(fetchAiExplicitLogsSuccess(
                        res.data && res.data[0] && res.data[0]?.results
                            ?
                            res.data[0].results
                            : []
                    ));
                }
                else {
                    dispatch(fetchAiExplicitLogsFail(true));
                }
            }).catch(error => {
                dispatch(fetchAiExplicitLogsFail(error));
            });
    };
};

export function resetUpdatedPodcast() {
    return (dispatch: Dispatch) => {
        dispatch(updatePodcastReset());
    }
}

export function resetfetchChangelogs() {
    return (dispatch: Dispatch) => {
        dispatch(fetchChangelogsReset());
    }
}

let urlParam = '?page=1&size=10&sort=id&status=PASSIVE&safe=true';

export function fetchPodcastsWithParam(params?: any) {

    urlParam = params ? '?page=1&size=10' + params : urlParam;

    return (dispatch: Dispatch) => {
        dispatch(updatePodcastReset());
        dispatch(fetchPodcastsReset());

        dispatch(fetchPodcastsStart());

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/podcasts/moderation${urlParam}`)
            .then((res: any) => {
                if (res.data) {
                    dispatch(fetchPodcastsSuccess(res.data));
                }
                else {
                    dispatch(fetchPodcastsFail(true));
                }
            }).catch((error) => {
                dispatch(fetchPodcastsFail(true));
            });
    };
};

// podcast in moderasyon edilmesi, durumunun değiştirilmesi
export function updatePodcast(id: number, status: any, message: string, selectedFilter?: string, from?: string) {
    urlParam = selectedFilter ? '?page=1&size=10&sort=&status=' + selectedFilter : urlParam;
    let reqEpi = {
        episodeId: id,
        approve: status,
        message: message
    };

    let reqPodc = {
        channelId: id,
        approve: status,
        message: message
    };
    const apiUrl = from === 'episode' || selectedFilter === 'episode' ? '/episode' : '';
    const reqBody = from === 'episode' || selectedFilter === 'episode' ? reqEpi : reqPodc;
    return (dispatch: Dispatch) => {

        dispatch(updatePodcastReset());
        dispatch(updatePodcastStart(id));

        axios
            .post(`${process.env.REACT_APP_BASE_URL}/podcasts/moderation${apiUrl}`, reqBody)
            .then((res: any) => {
                if (res.data && res.data.success) {
                    dispatch(updatePodcastSuccess());
                    dispatch(updatePodcastsSuccess(id, status));
                } else {
                    dispatch(updatePodcastFail());
                }
            }).catch(err => {
                dispatch(updatePodcastFail());
            });
    };
}

export function fetchAiEpisodes(param: any) {

    param = param ? param : {
        page: 1,
        size: 10,
        query: ''
    }

    return (dispatch: Dispatch) => {
        dispatch(fetchAiEpisodesReset());
        dispatch(fetchAiEpisodesStart());
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/podcasts/passiveEpisodes`, {
                params: param
            })
            .then((res: any) => {
                dispatch(fetchAiEpisodesSuccess(res?.data));
            }).catch((res: any) => {
                dispatch(fetchAiEpisodesFail(true));
            });
    };
}