import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import errorIcon from '../../assets/images/icons/error-icon.png';
import successIcon from '../../assets/images/icons/success-icon.png';
import modalIMG from '../../assets/images/icons/modal-error.svg';
import Button from '../../components/UI/Button/Button';
import Card from '../../components/UI/Card/Card';
import Input from '../../components/UI/Input/Input';
import Modal from '../../components/UI/Modal/Modal';
import Spinner from '../../components/UI/Spinner/Spinner';
import TextArea from '../../components/UI/TextArea/TextArea';
import Timer from '../../components/UI/Timer/Timer';
import { verificationEmailChangeFail, verificationEmailChangeInitialize, verifyChangeEmail, verifyMatchEmail, getCategories } from '../../store/createPodcast/actions';
import { EmailChangeVerificationActionTypes, EmailMatchVerificationActionTypes } from '../../store/createPodcast/actionTypes';
import { emailChangeErrorFormat } from '../../store/shared/utility';
import './AddInfoPodcast.scss';
import Dropdown from '../../components/UI/Dropdown/Dropdown';


type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & any;
class AddInfoPodcast extends React.Component<Props> {

  state = {
    successModal: false,
    errorModal: false,
    activationCodeModal: false,
    code: '',
    timerActive: false,
    selectedVal: { key: -1, value: 'Kategori seçiniz..' },
    catArr: [{}]
  }

  componentDidMount() {
    this.props.getCategories();
    setTimeout(() => {
      this.convertCategories();
    }, 1500);
  }

  convertCategories() {
    if (this.props.categories?.response?.length > 0) {
      let catArr: any[] = [{ key: -1, value: 'Kategori seçiniz..' }];
      this.props.categories.response.forEach((c: any) => {
        catArr.push({ key: c.categoryId, value: c.name.replace('&amp;', '&') })
      });
      this.setState({ catArr: catArr });

      // podcast bilgisini default seçili kategori olarak setlemek için:
      this.setState({
        selectedVal:
          this.props.validation?.response?.detail?.categoryId ? // podcastin categoryId dolu gelirse
            {
              key: this.props.validation?.response?.detail?.categoryId,
              value: this.props.validation?.response?.detail?.category
            }
            : // null gelirse
            { key: -1, value: 'Kategori seçiniz..' }
      })
    }
  }

  verifyMatchEmail = () => {
    this.props.verifyMatchEmail(this.props.validation.rssUrl, this.state.selectedVal.key);
    this.setState({
      successModal: true,
      errorModal: true,
      activationCodeModal: true,
      timerActive: true,
      code: ''
    });

  }
  verifyChangeEmail = () => {
    this.props.verifyChangeEmail(this.state.code, this.props.validation.rssUrl, this.state.selectedVal.key);
    this.setState({ activationCodeError: true });
  }
  activationCodeInputChange = (inputValue: string) => {
    this.props.verificationEmailChangeInitialize();
    this.setState({ code: inputValue })
  }
  modalClose = (redirect?: string) => {
    this.setState({
      successModal: false,
      errorModal: false,
      activationCodeModal: false,
      timerActive: false,
      code: ''
    });
    if (redirect && redirect !== '') {
      this.props.history.push('/');
    }
  }
  successModal = (info: any) => {
    return <Modal clickedOutside={true} title="" className="modal text-center" show={this.state.successModal} onClose={() => this.modalClose('/')}>
      <img alt="" src={successIcon} />
      <h2>Başarılı</h2>
      <p className="modal-notification notification">{info}</p>
      <Button text="Tamam" className="button--success" onClick={() => this.modalClose('/')} />
    </Modal>
  }
  errorModal = (error: any) => {
    return <Modal clickedOutside={true} title="" className="modal text-center" show={this.state.errorModal} onClose={() => this.modalClose()}>
      <img alt="" src={errorIcon} />
      <h2>Hata</h2>
      <p className="modal-notification notification ">{error}</p>
      <Button text="Tamam" onClick={() => this.modalClose()} />
    </Modal>
  }
  timerStopChange = () => {
    this.props.verificationEmailChangeError(emailChangeErrorFormat('TIME_EXPIRED'));
    this.setState({ timerActive: false })
  }
  inputClassName = () => {
    let result = this.props.emailChange;

    if (result.error && this.state.code !== '') {
      return 'input--error';
    }
    if (result.loading && this.state.code !== '') {
      return 'input--disabled';
    }
    if (!result.error && !result.loading && this.state.code === '') {
      return '';
    }
  }
  isDisplayField = () => {
    if (this.props.emailChange.error) {
      return this.props.emailChange.error.inputDisplay;
    } else {
      return true;
    }
  }
  activationCodeModal = () => {
    let result = this.props.emailChange;
    const message = 'Podcast\'inizi başarıyla aldık, inceleme süreci başlatılmıştır.';

    // eklenen rss linkindeki mail adresinin sa**@tu**.com şeklinde görünmesi için:
    const mailArr = this.props.validation.response?.detail?.email ? this.props.validation.response.detail.email.split("@") : null;
    const mailText = mailArr ? mailArr[0].substring(0, 2) + "***@" + mailArr[1].substring(0, 2) + "***." + mailArr[1].substring(mailArr[1].indexOf(".") + 1) : "";

    return result.response && result.response.success ? this.successModal(message) : <Modal clickedOutside={false} title="" className="modal text-center" show={this.state.activationCodeModal}
      onClose={() =>
        this.modalClose()
      }
    >

      <img alt="" src={modalIMG}></img>

      {this.isDisplayField() ?
        <p className="align-text">Girdiğiniz email adresi RSS tanımındaki email adresi ile uyuşmadığı
        için {mailText} email adresine bir onay kodu gönderilmiştir.
        Onay kodunu 5dk içinde alttaki alana girmeniz gerekmektedir.</p>
        :
        <p className="align-text">Belirtilen süre içerisinde kodu girmediniz, lütfen tekrar deneyin.
        Teknik bir sorun olduğunu düşünüyorsanız <a href="mailto:info@podcaststudion.com">info@podcaststudion.com</a> adresi
        üzerinden iletişime geçebilirsiniz.</p>
      }

      {this.isDisplayField() ? <Timer active={this.isDisplayField() ? this.state.timerActive : false} timeBound={300000} timerStopChange={() => this.timerStopChange()} /> : null}

      {
        this.isDisplayField() ?
          <Input
            value=""
            // label="Aktivasyon Kodu"
            className={this.inputClassName()}
            disabled={this.props.emailChange.loading}
            onChange={(inputValue: any) => this.activationCodeInputChange(inputValue)}
            placeholder="Aktivasyon Kodu"
            icon="fa fa-key icon"
            iconHref={false}
          />
          : null
      }


      <p className="notification error">{result.error && this.state.code !== '' ? result.error.message : ''}</p>
      <div className="modal__content--buttons">
        {
          this.isDisplayField() ?
            <div>
              <Button className="button--secondary" text="Vazgeç" onClick={() => this.modalClose()} />
              <Button text="Gönder" className={this.state.code === '' ? 'button--secondary' : ''} disabled={this.state.code === ''} onClick={() => this.verifyChangeEmail()}></Button>
            </div> :
            <Button text="Tamam" disabled={false} onClick={() => this.modalClose()}></Button>
        }
      </div>
    </Modal >

  }
  verifyMatchEmailResult = () => {
    let result = this.props.emailMatch;

    if (result.response) {
      if (result.response.sendEmail === false) {
        const message = 'Podcast\'inizi başarıyla aldık, inceleme süreci başlatılmıştır.';
        return this.successModal(message);
      } else {
        return this.activationCodeModal();
      }
    }
    if (result.error) {
      return this.errorModal(result.error);
    }
    if (!result.error && !result.response) {
      return null;
    }

  }




  checkImageSource = (url: any) => {
    const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

    /** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
    const DATA_URL_PATTERN = /^data:(?:image(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video(?:mpeg|mp4|ogg|webm)|audio(?:mp3|oga|ogg|opus));base64,[a-z0-9+]+=*$/i;

    if (url === "null" || url === null || url === '' || url.length === 0 || url === "about:blank") {
      return null;
    };
    if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) {
      const image = React.createElement("img", { src: url, alt: '' });
      return image;
    };

    return null;
  }

  selectionChange = (selectedVal: any) => {
    this.setState({ selectedVal: { key: selectedVal.key, value: selectedVal.value } });
  }

  render() {

    return (
      <div className="layout" id="add-info-podcast-page">
        <Card >
          <h1>Podcast Yükle</h1>
          {this.props.validation.loading ? <Spinner /> :
            this.props.validation.response && this.props.validation.response.detail ?
              <div className={`upload-podcast-form ${this.props.emailMatch.loading ? 'loading' : ''}`}>
                <div className="upload-podcast-form--img-area">
                  {this.checkImageSource(this.props.validation.response.detail.imageUrl)}

                </div>
                <div>
                  <Input disabled={true} className="input--disabled" value={this.props.validation.rssUrl} label="RSS Link" />
                  <Input disabled={true} className="input--disabled" value={this.props.validation.response.detail.title} label="Başlık" />
                  <Input disabled={true} className="input--disabled" value={this.props.validation.response.detail.author} label="Yazar" />
                  <Input disabled={true} className="input--disabled" value={this.props.validation.response.detail.language} label="Dil" />
                  <Input disabled={true} className="input--disabled" value={this.props.validation.response.detail.type} label="Tür" />
                  <Dropdown
                    className={'verify-cat' + ((this.state.selectedVal.key < 0) ? ' verify-error' : '')}
                    selectionChange={(selectedVal: any) => this.selectionChange(selectedVal)}
                    options={
                      this.state.catArr ? this.state.catArr : []
                    }
                    value={this.state.selectedVal}
                    disabled={false}
                    selectedOption={this.state.selectedVal} />

                  <TextArea label="Açıklama" value={this.props.validation.response.detail.description} disabled={true} />
                  <Button
                    disabled={this.state.selectedVal.key < 0}
                    text="Devam Et"
                    onClick={() => this.verifyMatchEmail()}
                  />
                </div>
              </div>
              : <p className="notification">Podcast bilgileri bulunamamıştır.</p>
          }

        </Card>
        { this.verifyMatchEmailResult()}

      </div >
    )
  }

}


const mapDispatchToProps = (dispatch: Dispatch<EmailMatchVerificationActionTypes | EmailChangeVerificationActionTypes>) => {

  return bindActionCreators(
    {
      verifyMatchEmail, verifyChangeEmail,
      verificationEmailChangeInitialize: () => dispatch(verificationEmailChangeInitialize()),
      verificationEmailChangeError: (error) => dispatch(verificationEmailChangeFail(error)),
      getCategories

    }
    , dispatch);

};
const mapStateToProps = (state: any) => {
  return {
    validation: state.validation,
    emailMatch: state.emailMatch,
    emailChange: state.emailChange,
    categories: state.categories,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddInfoPodcast);
