import { IPodcastList } from "./interfaces";

export const FETCH_PODCASTS_START = 'FETCH_PODCASTS_START';
export const FETCH_PODCASTS_SUCCESS = 'FETCH_PODCASTS_SUCCESS';
export const FETCH_PODCASTS_FAIL = 'FETCH_PODCASTS_FAIL';
export const FETCH_PODCASTS_RESET = 'FETCH_PODCASTS_RESET';
export const UPDATE_PODCASTS_SUCCESS = 'UPDATE_PODCASTS_SUCCESS';


export const UPDATE_PODCAST_START = 'UPDATE_PODCAST_START';
export const UPDATE_PODCAST_SUCCESS = 'UPDATE_PODCAST_SUCCESS';
export const UPDATE_PODCAST_FAIL = 'UPDATE_PODCAST_FAIL';
export const UPDATE_PODCAST_RESET = 'UPDATE_PODCAST_RESET';


export const FETCH_MORE_PODCASTS_START = 'FETCH_MORE_PODCASTS_START';
export const FETCH_MORE_PODCASTS_SUCCESS = 'FETCH_MORE_PODCASTS_SUCCESS';
export const FETCH_MORE_PODCASTS_FAIL = 'FETCH_MORE_PODCASTS_FAIL';
export const FETCH_MORE_PODCASTS_RESET = 'FETCH_MORE_PODCASTS_RESET';

//FETCH PODCAST

interface FetchPodcastsStartAction {
    type: typeof FETCH_PODCASTS_START
}
interface FetchPodcastsResetAction {
    type: typeof FETCH_PODCASTS_RESET
}
  
interface FetchPodcastsSuccessAction {
    type: typeof FETCH_PODCASTS_SUCCESS
    payload:IPodcastList
}

interface FetchPodcastsFailAction {
    type: typeof FETCH_PODCASTS_FAIL,
    error:any 
};









interface UpdatePodcastsSuccessAction {
    type: typeof UPDATE_PODCASTS_SUCCESS,
    podcastId:any,
    status:any 
};
//FETCH UPDATE PODCAST

interface UpdatePodcastStartAction {
    type: typeof UPDATE_PODCAST_START,
    updatedPodcastId:number
}
  
interface UpdatePodcastResetAction {
    type: typeof UPDATE_PODCAST_RESET
}

interface UpdatePodcastSuccessAction {
    type: typeof UPDATE_PODCAST_SUCCESS
}

interface UpdatePodcastFailAction {
    type: typeof UPDATE_PODCAST_FAIL
} 

//FETCH PODCASTS MORE


interface FetchMorePodcastsStartAction {
    type: typeof FETCH_MORE_PODCASTS_START
}

interface FetchMorePodcastsResetAction {
    type: typeof FETCH_MORE_PODCASTS_RESET
}

interface FetchMorePodcastsSuccessAction {
    type: typeof FETCH_MORE_PODCASTS_SUCCESS
    payload:IPodcastList 
}
interface FetchMorePodcastsFailAction {
    type: typeof FETCH_MORE_PODCASTS_FAIL
    error:any 
}

export interface PodcastsState {
    podcasts: IPodcastList,
    moreLoading:boolean,
    loading: boolean,
    error:any,
    message:string 
}
export interface UpdatePodcastState {
    success:boolean,
    message:string | null ,
    error:any ,
    loading:boolean,
    updatedPodcastId:number 
}

export type PodcastsActionTypes = (
    FetchPodcastsStartAction | 
    FetchPodcastsSuccessAction | 
    FetchPodcastsFailAction |
    FetchPodcastsResetAction |
    FetchMorePodcastsStartAction |
    FetchMorePodcastsSuccessAction |
    FetchMorePodcastsFailAction |
    FetchMorePodcastsResetAction | 
    UpdatePodcastsSuccessAction
); 

export type UpdatePodcastActionTypes = (
    UpdatePodcastFailAction | 
    UpdatePodcastStartAction | 
    UpdatePodcastSuccessAction | 
    UpdatePodcastResetAction)
; 
