import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import loginReducer from './auth/login/reducers';
import registerReducers from './auth/register/reducers';
import episodesReducer from './episodes/reducers';
import podcastReducer from './createPodcast/reducers';
import podcastsReducer from './podcasts/reducers';
import modPodcastsReducer from './moderations/reducers';

const rootReducer = combineReducers({
   ...podcastReducer,
   ...podcastsReducer,
   ...episodesReducer,
   ...loginReducer,
   ...registerReducers,
   ...modPodcastsReducer,
});

const store = createStore(rootReducer,compose(applyMiddleware(thunk)));
    

export default store;